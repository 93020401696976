import NotFoundPage from './pages/error/not_found_page'
import TopPage from './pages/home/top_page'
import LoginPage from './pages/login/login_page'
import PasswordResetRequestPage from './pages/password/password_reset_request_page'
import PasswordResetSendPage from './pages/password/password_reset_send_page'
import PasswordResetInputPage from './pages/password/password_reset_input_page'
import PasswordResetCompletePage from './pages/password/password_reset_complete_page'
import KuchikomiSearchPage from './pages/search/kuchikomi_search_page'
import UserSearchPage from './pages/search/user_search_page'
import EnterpriseSearchPage from './pages/search/enterprise_search_page'
import EnterpriseDetailPage from './pages/detail/enterprise_detail_page'
import UserDetailPage from './pages/detail/user_detail_page'
import EnterpriseRankingPage from './pages/ranking/enterprise_ranking_page'
import UserRankingPage from './pages/ranking/user_ranking_page'
import KuchikomiRankingPage from './pages/ranking/kuchikomi_ranking_page'
import PolicyPrivacyPage from './pages/policy/policy_privacy_page'
import PolicyUserPage from './pages/policy/policy_user_page'
import PolicyLawPage from './pages/policy/policy_law_page'
import FaqPage from './pages/faq/faq_page'
import ContactPage from './pages/contact/contact_page'
import ContactConfirmPage from './pages/contact/contact_confirm_page'
import ContactCompletePage from './pages/contact/contact_complete_page'
import KuchikomiCreatePage from './pages/kuchikomi/kuchikomi_create_page'
import InformationDetailPage from './pages/information/information_detail.page'
import MyPageTop from './pages/mypage/mypage_top'
import MyPageKuchikomi from './pages/mypage/mypage_kuchikomi'
import MyPageFollow from './pages/mypage/mypage_follows'
import MyPageFollower from './pages/mypage/mypage_follower'
import MyPageEnterpriseFollow from './pages/mypage/mypage_enterprise_follow'
import MyPageBookmark from './pages/mypage/mypage_bookmark'
import MyPageAboutMemberRank from './pages/mypage/mypage_about_member_rank'
import MyPageNotification from './pages/mypage/mypage_notification'
import MyPageUpdateUserInfo from './pages/mypage/mypage_update_user_info'
import InformationListPage from './pages/information/informatio_list_page'
import MyPageKuchikomiRanking from './pages/mypage/mypage_kuchikomi_ranking'
import MyPagePointExchangePage from './pages/mypage/mypage_point_exchange_page'
import RegisterUserPage from './pages/register/register_user_page'
import LikePointPage from './pages/guide/like_point_page'
import RatingPage from './pages/guide/rating_page'
import ForUserPage from './pages/guide/for_user_page'
import ForEnterprisePage from './pages/guide/for_enterprise_page'
import PlanPricePage from './pages/guide/plan_price_page'
import SendRegisterEnterpriseEmailPage from './pages/login/send_register_enterprise_email_page'
import RegisterEnterprisePage from './pages/register/register_enterprise_page'
import RegisterEnterpriseCompletePage from './pages/register/register_enterprise_complete_page'
import RegisterUserCompletePage from './pages/register/register_user_complete_page'
import CommunityGuidePage from './pages/guide/community_page'
import NoticePage from './pages/guide/notice_page'
import KuchikomiDetailPage from './pages/kuchikomi/kuchikomi_detail_page'
import MyPageTicketPage from './pages/mypage/mypage_ticket_page'
import ExpoPage from './pages/expo/expo_page'
import ExpoCategoryPage from './pages/expo/expo_category_page'

type Route = {
  path: string
  element: React.FC
  auth?: boolean
}

export const routes: Route[] = [
  {
    path: '/',
    element: TopPage,
  },
  {
    path: '/informations',
    element: InformationListPage,
  },
  {
    path: '/login',
    element: LoginPage,
  },
  {
    path: '/password/reset/request',
    element: PasswordResetRequestPage,
  },
  {
    path: '/password/reset/send',
    element: PasswordResetSendPage,
  },
  {
    path: '/password/reset/input/:token',
    element: PasswordResetInputPage,
  },
  {
    path: '/password/reset/complete',
    element: PasswordResetCompletePage,
  },
  {
    path: '/register/user',
    element: RegisterUserPage,
  },
  {
    path: '/register/user/complete',
    element: RegisterUserCompletePage,
  },
  {
    path: '/register/enterprise/send',
    element: SendRegisterEnterpriseEmailPage,
  },
  {
    path: '/register/enterprise/:token',
    element: RegisterEnterprisePage,
  },
  {
    path: '/register/enterprise/complete',
    element: RegisterEnterpriseCompletePage,
  },
  {
    path: '/search/enterprise',
    element: EnterpriseSearchPage,
  },
  {
    path: '/search/kuchikomi',
    element: KuchikomiSearchPage,
  },
  {
    path: '/kuchikomi/create/:id',
    element: KuchikomiCreatePage,
  },
  {
    path: '/kuchikomi/detail/:id',
    element: KuchikomiDetailPage,
  },
  {
    path: '/information/:id',
    element: InformationDetailPage,
  },
  {
    path: '/search/user',
    element: UserSearchPage,
  },
  {
    path: '/enterprises/:id',
    element: EnterpriseDetailPage,
  },
  {
    path: '/users/:id',
    element: UserDetailPage,
  },
  {
    path: '/ranking/enterprise',
    element: EnterpriseRankingPage,
  },
  {
    path: '/ranking/user',
    element: UserRankingPage,
  },
  {
    path: '/ranking/kuchikomi',
    element: KuchikomiRankingPage,
  },
  {
    path: '/policy/privacy',
    element: PolicyPrivacyPage,
  },
  {
    path: '/policy/user',
    element: PolicyUserPage,
  },
  {
    path: '/policy/law',
    element: PolicyLawPage,
  },
  {
    path: '/faq',
    element: FaqPage,
  },
  {
    path: '/contact',
    element: ContactPage,
  },
  {
    path: '/contact/confirm',
    element: ContactConfirmPage,
  },
  {
    path: '/contact/complete',
    element: ContactCompletePage,
  },
  {
    path: '/guide/like/point',
    element: LikePointPage,
  },
  {
    path: '/guide/rating',
    element: RatingPage,
  },
  {
    path: '/guide/user',
    element: ForUserPage,
  },
  {
    path: '/guide/community',
    element: CommunityGuidePage,
  },
  {
    path: '/guide/notice',
    element: NoticePage,
  },
  {
    path: '/guide/enterprise',
    element: ForEnterprisePage,
  },
  {
    path: '/guide/plan/price',
    element: PlanPricePage,
  },
  {
    path: '/mypage',
    element: MyPageTop,
    auth: true,
  },
  {
    path: '/mypage/kuchikomi',
    element: MyPageKuchikomi,
    auth: true,
  },
  {
    path: '/mypage/kuchikomi/ranking',
    element: MyPageKuchikomiRanking,
    auth: true,
  },
  {
    path: '/mypage/follow',
    element: MyPageFollow,
    auth: true,
  },
  {
    path: '/mypage/follower',
    element: MyPageFollower,
    auth: true,
  },
  {
    path: '/mypage/enterprise-follow',
    element: MyPageEnterpriseFollow,
    auth: true,
  },
  {
    path: '/mypage/bookmark',
    element: MyPageBookmark,
    auth: true,
  },
  {
    path: '/mypage/about-member-rank',
    element: MyPageAboutMemberRank,
    auth: true,
  },
  {
    path: '/mypage/notification',
    element: MyPageNotification,
    auth: true,
  },
  {
    path: '/mypage/edit-profile',
    element: MyPageUpdateUserInfo,
    auth: true,
  },
  {
    path: '/mypage/point/exchange',
    element: MyPagePointExchangePage,
    auth: true,
  },
  {
    path: '/mypage/ticket',
    element: MyPageTicketPage,
    auth: true,
  },
  {
    path: '/kuchikomi-banpaku',
    element: ExpoPage,
    auth: false,
  },
  {
    path: '/kuchikomi-banpaku/:category',
    element: ExpoCategoryPage,
    auth: false,
  },
  {
    path: '/*',
    element: NotFoundPage,
  },
]
