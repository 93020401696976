import React, { FC, useEffect, useState } from 'react'
import { FavoriteIcon } from '../../images/icons/favorite_icon'
import { getKiloString } from '../../utils/numeric_util'
import './favorite.css'
import { LicenseType } from '../../enums/LicenseType'
import { useAuth } from '../../hooks/use_auth'

// 数値の表記
type FavoriteButtonProps = {
  isLiked: boolean
  isSp?: boolean
  favoriteCount: number
  sendPoint?: (point: number) => void
}

const FavoriteButton: FC<FavoriteButtonProps> = (props) => {
  const { user } = useAuth()

  // Check if user is a corporate user
  const isKuchikomiUser = user?.licenseType === LicenseType.NORMAL
  const [favCount, setFavCount] = useState<number | null>(null)
  const [favClass, setFavClass] = useState<string>('')
  const [ringClass, setRingClass] = useState<string>('')
  const [liked, setLiked] = useState<boolean>(false)

  useEffect(() => {
    setLiked(props.isLiked)
  }, [props.isLiked])

  useEffect(() => {
    setFavCount(props.favoriteCount)
  }, [props.favoriteCount])

  const onClickFavorite = async () => {
    // Disable functionality for corporate users
    if (!isKuchikomiUser) {
      return
    }

    // if (!activeBtn) {
    //   return
    // }
    if (props.sendPoint !== undefined) {
      setFavClass('')
      setRingClass('')

      await sleep(10)

      props.sendPoint(1)
      setFavClass('fav_icon')
      setRingClass('ring')
      setLiked(true)
      // setFavCount(favCount ? favCount + 1 : 1)

      await sleep(300)
      setFavClass('')
      setRingClass('')
    }
  }

  const sleep = (msec: number) =>
    new Promise((resolve) => setTimeout(resolve, msec))

  return (
    <div
      className={`flex justify-start ${
        props.sendPoint !== undefined && isKuchikomiUser
          ? 'cursor-pointer'
          : 'cursor-default'
      }`}
      onClick={onClickFavorite}
    >
      <div className="flex flex-col justify-center relative">
        <div className={favClass}>
          <FavoriteIcon isActive={isKuchikomiUser ? liked : false} />
        </div>
        <div className={ringClass}></div>
      </div>
      {favCount !== null && (
        <div className="flex flex-col justify-center">
          <p
            className={`${
              !isKuchikomiUser
                ? 'text-main-lightGray'
                : liked
                  ? 'text-main-red'
                  : 'text-main-lightGray'
            } text-xs pl-1`}
          >
            {getKiloString(favCount)}
          </p>
        </div>
      )}
    </div>
  )
}

export default FavoriteButton
