import React, { useEffect } from 'react'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import useUser from './hooks/use_user'
import useNotification from './hooks/use_notification'
import MyPageTemplate from './components/templates/mypage_template'
import LoadingSpinner from '../../components/loading/loading_spinner'
import PagiNationCursor from '../../components/pagination/pagination_cursor'

const MyPageNotification = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [user, userLoading] = useUser()
  const [notifications, size, currentCursor, isLoading, notificationHandler] =
    useNotification()
  useEffect(() => {
    if (user) {
      notificationHandler.load()
    }
  }, [user])

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const notificationElems = (
    <>
      <div className="rounded-xl overflow-hidden bg-white">
        {notifications.map((notification, index) => {
          return (
            <div
              key={`follow-${String(index)}`}
              className={`p-4 flex items-start ${index === 0 ? '' : 'border-t-2 border-main-gray'}`}
            >
              <div
                className="min-w-12 w-12 h-12 rounded-full bg-cover bg-center"
                style={{
                  backgroundImage: `url(${notification.userIcon})`,
                }}
              ></div>
              <div
                className={`pt-2 pl-3 ${
                  notification.url ? 'cursor-pointer text-main-blue' : ''
                }`}
                onClick={() => {
                  if (notification.url) {
                    window.location.href = notification.url
                  }
                }}
              >
                {notification.message}
              </div>
            </div>
          )
        })}
      </div>
      {notifications.length === 0 && !isLoading && (
        <div className="text-center py-4 text-gray-400">通知はありません。</div>
      )}
    </>
  )

  const paginationCursor =
    notifications.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          notificationHandler.load(currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToIndexSection()
          notificationHandler.load(currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          notificationHandler.load((pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <MyPageTemplate
      head="通知"
      user={user}
      isSp={isSp}
      userLoading={userLoading}
    >
      <div ref={IndexSectionRef} />
      {isLoading || userLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="pb-8">{notificationElems}</div>
          <div className="pb-8">{paginationCursor}</div>
        </>
      )}
    </MyPageTemplate>
  )

  return content
}

export default MyPageNotification
