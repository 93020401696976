import React, { FC, useEffect, useState } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import ExpoFVImage from './images/fv.png'
import { Helmet } from 'react-helmet'
import {
  ExpoCategoryLabel,
  ExpoCategoryValue,
  ExpoCategoryValues,
} from './category'
import { EnterpriseRanking } from '../../model/Ranking'
import Head2 from '../../components/heads/head2'
import LoadingSpinner from '../../components/loading/loading_spinner'
import { EnterpriseRankingCard } from '../../components/card/enterprise_card'
import { useSnackBar } from '../../providers/snack_bar_provider'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import { Link, useNavigate, useParams } from 'react-router-dom'
import FlatButton from '../../components/buttons/flat_button'

const ogpDescription =
  'クチコミ万博では、Osaka EXPO 2025の最新情報や参加者の評判・レビュー・評価を集め、海外＆国内パビリオン、グルメ、グッズ＆ショップ、イベントなど、万博をより深く楽しむための情報を提供します。実際の体験をもとに、万博の魅力を発見しましょう！'
const ogpKeywords =
  '万博,Expo,大阪,Osaka,クチコミ,クチコミ,レビュー,採点,評価,評判,カスタマーレビュー,コメント,ランキング,持続可能,サステナブル,Sustainable,ESG ,エシカル , ethical,Review'
const ogpTitle =
  'クチコミ万博 | Osaka EXPO 2025の評判・レビューで楽しむ海外＆国内パビリオン、グルメ＆イベント'

const ExpoCategoryPage: FC = () => {
  const isSp = useSpBreakPoint()
  const navigate = useNavigate()
  const pathParams = useParams<{ category: string }>()
  const category = pathParams.category as ExpoCategoryValue
  const [rankingLoading, setRankingLoading] = useState(false)
  const [enterpriseRankings, setEnterpriseRankings] = useState<
    EnterpriseRanking[]
  >([])
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()

  useEffect(() => {
    loadEnterpriseRankings()
  }, [category])

  const loadEnterpriseRankings = async () => {
    setRankingLoading(true)

    await apiClient.ranking
      .enterpriseExpoList({ category: category })
      .then((res) => {
        const result = res.data
        setEnterpriseRankings(result.data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return null
      })
      .finally(() => {
        setRankingLoading(false)
      })
  }

  return (
    <CommonTemplate isPadding={!isSp} isSp={isSp} isShowPostButton={false}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Sharp|Material+Icons+Two+Tone"
          rel="stylesheet"
        />
        <meta name="description" content={ogpDescription} />
        <meta property="og:description" content={ogpDescription} />
        <meta name="twitter:description" content={ogpDescription} />
        <meta name="keywords" content={ogpKeywords} />
        <meta property="og:image" content={ExpoFVImage} />
        <meta name="twitter:image:src" content={ExpoFVImage} />
        <meta property="og:title" content={ogpTitle} />
        <meta name="twitter:title" content={ogpTitle} />
        <title>{ogpTitle}</title>
      </Helmet>

      <Link to="/kuchikomi-banpaku">
        <img src={ExpoFVImage} alt="expo" className="w-full mb-12" />
      </Link>
      <div className="mb-16">
        <h4 className="mb-4 font-bold text-2xl">グループ検索</h4>
        <div className="flex items-center flex-wrap gap-4">
          {ExpoCategoryValues.map((cate) => (
            <Link to={'/kuchikomi-banpaku/' + cate} key={cate}>
              <div className="bg-main-darkblue py-4 w-52 text-center text-white font-bold text-base rounded-md">
                {ExpoCategoryLabel[cate]}
              </div>
            </Link>
          ))}
        </div>
      </div>

      <div className="mb-4">
        <div className="mb-8">
          <Head2 title={`${ExpoCategoryLabel[category]}ランキング`} />
        </div>
        {rankingLoading ? (
          <LoadingSpinner className="mx-auto" />
        ) : (
          <>
            <div className="flex md:justify-between justify-center flex-wrap">
              {enterpriseRankings.map((item, index) => {
                return (
                  <div
                    key={`ranking-${String(index)}`}
                    className="md:w-[47.5%] w-full min-w-[280px] pb-4"
                  >
                    <EnterpriseRankingCard ranking={item} />
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>

      <FlatButton
        bgColor="blue"
        onClick={() => {
          navigate('/kuchikomi-banpaku')
        }}
        className="bg-main-darkblue"
      >
        万博TOPへ
      </FlatButton>
    </CommonTemplate>
  )
}

export default ExpoCategoryPage
