import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import Notification from '../../../model/Notification'
import { useSnackBar } from '../../../providers/snack_bar_provider'

type Handler = {
  load: (cursor?: number) => Promise<void>
}

const useNotification = (): [
  Array<Notification>,
  number,
  number,
  boolean,
  Handler,
] => {
  const GETLIMIT = 50
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalSize, setTotalSize] = useState(0)
  const [apiCursor, setApiCursor] = useState(0)
  const [notifications, setNotifications] = useState<Array<Notification>>([])

  const load = useCallback(
    async (cursor = apiCursor) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)
      await apiClient.notification
        .notificationList({
          cursor: cursor,
          limit: GETLIMIT,
        })
        .then((res) => {
          const result = res.data
          if (result.total !== totalSize) {
            setTotalSize(result.total)
          }
          if (result.data) {
            const nextNotifications = [...notifications].concat(result.data)
            setNotifications(nextNotifications)
            setApiCursor(cursor + result.data.length)
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => setIsLoading(false))
    },
    [apiCursor, notifications, totalSize]
  )

  return [notifications, totalSize, apiCursor, isLoading, { load }]
}

export default useNotification
