import React, { FC } from 'react'
import Notification from '../../../../model/Notification'

type Props = {
  notifications: Notification[]
}

const NotificationCard: FC<Props> = (props) => {
  return (
    <div className="rounded-xl bg-white p-4">
      <p className="text-lg sm:text-2xl pb-4 font-bold">通知一覧</p>
      <div className="rounded-xl overflow-hidden bg-white">
        {props.notifications.map((notification, index) => {
          return (
            <div
              key={`follow-${String(index)}`}
              className={`p-4 flex items-start ${index === 0 ? '' : 'border-t-2 border-main-gray'}`}
            >
              <div
                className="min-w-12 w-12 h-12 rounded-full bg-cover bg-center"
                style={{
                  backgroundImage: `url(${notification.userIcon})`,
                }}
              ></div>
              <div
                className={`pt-2 pl-3 ${
                  notification.url ? 'cursor-pointer text-main-blue' : ''
                }`}
                onClick={() => {
                  if (notification.url) {
                    window.location.href = notification.url
                  }
                }}
              >
                {notification.message}
              </div>
            </div>
          )
        })}
        {props.notifications.length === 0 && (
          <p className="text-main-dimgray text-sm">通知はありません</p>
        )}
      </div>
    </div>
  )
}

export default NotificationCard
