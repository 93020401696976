import React, { createContext, useContext, useEffect, useState } from 'react'

const AppContext = createContext<{ isApp: boolean }>({ isApp: false })

/// アプリからのリクエストかどうかを判断する
export const AppProvider = ({ children }: { children: React.ReactNode }) => {
  const [isApp, setIsApp] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    setIsApp(params.get('isApp') === 'true')
  }, [])

  return <AppContext.Provider value={{ isApp }}>{children}</AppContext.Provider>
}

export const useAppContext = () => useContext(AppContext)
