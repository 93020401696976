import { useCallback, useState } from 'react'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../../utils/network_util'
import { useSnackBar } from '../../../providers/snack_bar_provider'
import Coupon from '../../../model/Coupon'

type Handler = {
  load: (sortOrder?: CouponSortOrder, cursor?: number) => Promise<void>
}

export type CouponSortOrder = 'new' | 'old' | 'expensive' | 'reasonable'

const useCoupons = (
  getLimit = 20
): [Array<Coupon>, number, number, boolean, Handler] => {
  const apiClient = getAppClient()

  const [isLoading, setIsLoading] = useState(false)
  const [totalSize, setTotalSize] = useState(0)
  const [cursor, setCursor] = useState(0)
  const [coupons, setCoupons] = useState<Array<Coupon>>([])
  const { showSnackBar } = useSnackBar()

  const load = useCallback(
    async (sortOrder: CouponSortOrder = 'new', cursor = 0) => {
      if (cursor < 0 || (totalSize && cursor >= totalSize)) {
        return
      }

      setIsLoading(true)
      await apiClient.coupons
        .couponsList({
          cursor: cursor,
          limit: getLimit,
          search_order: sortOrder,
        })
        .then((res) => {
          const result = res.data
          if (totalSize !== result.total) {
            setTotalSize(result.total)
          }
          if (result.data) {
            setCursor(cursor)
            setCoupons(
              result.data.map(
                (c) =>
                  new Coupon(
                    c.id,
                    c.title,
                    c.description,
                    c.imageUrl,
                    c.expireDate,
                    c.point,
                    c.couponService
                  )
              )
            )
          }
        })
        .catch((e: ApiErrorResponse) => {
          handleError(e, showSnackBar)
          return
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [totalSize, cursor, coupons]
  )

  return [coupons, totalSize, cursor, isLoading, { load: load }]
}

export default useCoupons
