import React, { useEffect, useState } from 'react'
import MyPageTemplate from './components/templates/mypage_template'
import useUser from './hooks/use_user'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import LoadingSpinner from '../../components/loading/loading_spinner'
import useCoupons, { CouponSortOrder } from './hooks/use_coupons'
import { HoldPointIcon } from '../../images/icons/hold_point_icon'
import { useModal } from 'react-hooks-use-modal'
import Coupon from '../../model/Coupon'
import CouponDetailModal from './components/modal/coupon_detail_modal'
import CouponExchangeConfirmModal from './components/modal/coupon_exchange_confirm_modal'
import CouponExchangeCompleteModal from './components/modal/coupon_exchange_complete_modal'
import { trimText } from '../../utils/string_util'

const MyPagePointExchangePage = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [sortOrder, setSortOrder] = useState<CouponSortOrder>('new')
  const [coupons, totalSize, currentCursor, couponLoading, couponHandler] =
    useCoupons(PAGE_SIZE)
  const [user, userLoading] = useUser()
  const [DetailModal, openDetailModal, closeDetailModal] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })
  const [ConfirmModal, openConfirmModal, closeConfirmModal] = useModal('root', {
    preventScroll: false,
    focusTrapOptions: {
      clickOutsideDeactivates: true,
    },
  })
  const [CompleteModal, openCompleteModal, closeCompleteModal] = useModal(
    'root',
    {
      preventScroll: false,
      focusTrapOptions: {
        clickOutsideDeactivates: true,
      },
    }
  )
  const [selectCoupon, setSelectCoupon] = useState<Coupon | null>(null)

  useEffect(() => {
    couponHandler.load(sortOrder)
  }, [sortOrder])

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const sortSelectElems = (
    <>
      <div className="flex items-center">
        {[
          { title: '新着順', value: 'new' },
          { title: '古い順', value: 'old' },
          { title: '低い順', value: 'reasonable' },
          { title: '高い順', value: 'expensive' },
        ].map((item) => {
          return (
            <button
              key={item.value}
              className={`${
                item.value === sortOrder
                  ? 'bg-main-blue text-white'
                  : 'bg-white text-main-blue'
              } py-1 px-2 sm:px-4 rounded-md mr-2 sm:mr-4 text-sm`}
              onClick={() => {
                setSortOrder(item.value as CouponSortOrder)
              }}
            >
              {item.title}
            </button>
          )
        })}
      </div>
    </>
  )

  const couponElems = (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8">
        {coupons?.map((coupon, index) => {
          return (
            <div
              key={`coupons-${String(index)}`}
              className="rounded-md cursor-pointer"
              onClick={() => {
                setSelectCoupon(coupon)
                openDetailModal()
              }}
            >
              <div className="py-2 sm:py-4 px-4 sm:px-8 bg-white rounded-t-md">
                <h4 className="font-bold mb-2">{coupon.title}</h4>
                <div className="flex mb-2 items-start">
                  <div className="pt-1">
                    <img
                      src={coupon.imageUrl}
                      className="h-[80px] object-contain"
                    />
                  </div>
                  <div className="w-[calc(100%-120px)] pl-4 text-sm whitespace-pre-wrap break-words truncate">
                    {trimText(coupon.description, 30)}
                  </div>
                </div>
                <p className="text-sm">
                  有効期限：{coupon.expireDate.format('YYYY/M/D')}まで
                </p>
              </div>
              <div className="rounded-b-md bg-main-blue py-2 flex justify-center items-baseline">
                <HoldPointIcon
                  color="white"
                  size="md"
                  additionalClassName="relative top-[2px]"
                />
                <span className="text-white text-3xl font-bold px-1">
                  {coupon.point.toLocaleString()}
                </span>
                <span className="text-white text-xs">でゲット!!</span>
              </div>
            </div>
          )
        })}
      </div>
      {coupons.length === 0 && !couponLoading && (
        <div className="text-center py-4 text-gray-400">
          現在交換可能なチケットはありません
        </div>
      )}
    </>
  )

  const paginationCursor =
    coupons.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(totalSize / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          couponHandler.load(sortOrder, currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToIndexSection()
          couponHandler.load(sortOrder, currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          couponHandler.load(sortOrder, (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <MyPageTemplate
      userLoading={userLoading}
      head="ポイント交換"
      user={user}
      isSp={isSp}
      subHead={
        couponLoading
          ? ''
          : `全${totalSize}件中 ${currentCursor + 1}〜${currentCursor + PAGE_SIZE > totalSize ? totalSize : currentCursor + PAGE_SIZE}件表示`
      }
    >
      <div ref={IndexSectionRef} />
      <div className="pb-4 sm:pb-8">{sortSelectElems}</div>
      {couponLoading || userLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="pb-8">{couponElems}</div>
          <div className="pb-8">{paginationCursor}</div>
        </>
      )}
      {selectCoupon && (
        <>
          <DetailModal>
            <CouponDetailModal
              coupon={selectCoupon}
              close={closeDetailModal}
              confirm={() => {
                closeDetailModal()
                openConfirmModal()
              }}
            />
          </DetailModal>
          <ConfirmModal>
            <CouponExchangeConfirmModal
              coupon={selectCoupon}
              close={closeConfirmModal}
              complete={() => {
                closeConfirmModal()
                openCompleteModal()
              }}
              back={() => {
                closeConfirmModal()
                openDetailModal()
              }}
            />
          </ConfirmModal>
          <CompleteModal>
            <CouponExchangeCompleteModal close={closeCompleteModal} />
          </CompleteModal>
        </>
      )}
    </MyPageTemplate>
  )

  return content
}

export default MyPagePointExchangePage
