import React, { FC } from 'react'
import Information from '../../../../model/Information'
import { getKuchikomiDate } from '../../../../utils/datetime_util'
import { Link } from 'react-router-dom'

type InformationCardProps = {
  informations: Array<Information>
}

const InformationCard: FC<InformationCardProps> = (props) => {
  return (
    <div className="rounded-xl bg-white p-4">
      <p className="text-lg sm:text-2xl pb-4 font-bold">
        SDGsクチコミLodgeからのお知らせ
      </p>
      <div className="overflow-scroll max-h-[200px]">
        {props.informations.map((item, index) => {
          return (
            <Link
              to={`/information/${item.id}`}
              key={`information-${String(index)}`}
            >
              <div className="flex justify-start py-1">
                <p className="text-sm text-main-blue pr-4">
                  {getKuchikomiDate(item.createdAt, false)}
                </p>
                <p className="text-sm underline line-clamp-1">{item.title}</p>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default InformationCard
