import { BrowserRouter, Routes, Route } from 'react-router-dom'
import React from 'react'
import ReactDOM from 'react-dom/client'
import ReactGA from 'react-ga4'
import { RecoilRoot } from 'recoil'
import RecoilNexus from 'recoil-nexus'
import { LoadingProvider } from './providers/loading_provider'
import { SnackBarProvider } from './providers/snack_bar_provider'
import AuthProvider from './providers/auth_provider'
import ScrollTop from './hooks/scroll_top'
import usePageViews from './hooks/use_page_view'
import AuthGuard from './components/guard/auth_guard'
import { routes } from './route'
import './css/main.css'
import './css/tailwind.css'
import { AppProvider } from './providers/app_provider'

ReactGA.initialize(process.env.REACT_APP_GA_TAG_ID ?? 'TEST-TOKEN', {
  testMode: process.env.REACT_APP_ENV !== 'production',
})
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
document.documentElement.setAttribute('lang', 'ja')

const App = () => {
  usePageViews()

  return (
    <Routes>
      {routes.map((route, index) =>
        route.auth ? (
          <Route
            key={`auth-route-${index}`}
            path={route.path}
            element={
              <AuthGuard>
                <route.element />
              </AuthGuard>
            }
          />
        ) : (
          <Route
            key={`route-${index}`}
            path={route.path}
            element={<route.element />}
          />
        )
      )}
    </Routes>
  )
}

root.render(
  <RecoilRoot>
    <RecoilNexus />
    <LoadingProvider>
      <SnackBarProvider>
        <AuthProvider>
          <AppProvider>
            {/* <ProductionReadyProvider> */}
            {/* <BasicAuthProvider> */}
            <BrowserRouter basename="">
              <React.StrictMode>
                <ScrollTop />
                <App />
              </React.StrictMode>
            </BrowserRouter>
            {/* </BasicAuthProvider> */}
            {/* </ProductionReadyProvider> */}
          </AppProvider>
        </AuthProvider>
      </SnackBarProvider>
    </LoadingProvider>
  </RecoilRoot>
)
