/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** ユーザー情報 */
export interface User {
  /** @example 2 */
  id: string
  /** @example "サンプル太郎" */
  displayName: string
  /** @example "https://user.sample.jpg" */
  iconUrl: string
  /** @example "私はテストプロフィールです。私はテストプロフィールです。私はテストプロフィールです。私はテストプロフィールです。" */
  profile?: string | null
  /** @example "キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。" */
  catchCopy?: string | null
  /** @example "https://sample.sample" */
  homepageUrl?: string | null
  /** @example "https://sample.sample" */
  twitterUrl?: string | null
  /** @example "https://sample.sample" */
  facebookUrl?: string | null
  /** @example "https://sample.sample" */
  instagramUrl?: string | null
  /** @example "https://sample.sample" */
  tiktokUrl?: string | null
  /** @example 3000 */
  followCount: number | null
  /** @example 3000 */
  followerCount: number | null
  interestingSDGs: SDGsCategory[]
  /** @example 0 */
  weeklyRank?: number | null
  /** @example 0 */
  yearlyRank?: number | null
  /** @example 0 */
  wholePeriodRank?: number | null
  /** @example 1000 */
  givedPointCount: number
  /** @example 1000 */
  holdPointCount: number
  /** @example 1000 */
  kuchikomiCount: number | null
  /** @example 1000 */
  kuchikomiEnterpriseCount: number | null
  /**
   * - code: '1'
   *   value: Diamond
   */
  memberRank: MemberRank
  /** @example true */
  yourFollower: boolean | null
}

/** ユーザー情報 */
export interface RegisterdUserInfo {
  /** @example "サンプル" */
  lastName: string | null
  /** @example "太郎" */
  firstName: string | null
  /** @example "サンプル太郎" */
  displayName: string
  /**
   * - code: employee
   *   value: 会社員
   * - code: public_officer
   *   value: 公務員
   * - code: self
   *   value: 自営業
   * - code: board_member
   *   value: 会社役員
   * - code: fleerance
   *   value: 自由業
   * - code: housewife
   *   value: 専業主婦（夫）
   * - code: student
   *   value: 学生
   * - code: part_time
   *   value: パート・アルバイト
   * - code: none
   *   value: 無職
   */
  gender: GenderCode | null
  /** @example "東京都" */
  prefecture: string | null
  /** @example "2000-01-01" */
  birthDay: string | null
  /** @example "https://user.sample.jpg" */
  iconUrl: string
  /** @example "sample@sample.sample" */
  email: string
  /** @example "私はテストプロフィールです。私はテストプロフィールです。私はテストプロフィールです。私はテストプロフィールです。" */
  profile: string | null
  /** @example "キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。" */
  catchCopy: string | null
  occupationCode: OccupationCode | null
  /** @example "https://sample.sample" */
  homepageUrl: string | null
  /** @example "https://sample.sample" */
  twitterUrl: string | null
  /** @example "https://sample.sample" */
  facebookUrl: string | null
  /** @example "https://sample.sample" */
  instagramUrl: string | null
  /** @example "https://sample.sample" */
  tiktokUrl: string | null
  interestingSDGs: SDGsCategory[]
}

/** クチコミに紐づくユーザー情報 */
export interface KuchikomiUser {
  /** @example 2 */
  id: string
  /** @example "サンプル太郎" */
  displayName: string
  /** @example "https://user.sample.jpg" */
  iconUrl: string
}

/** クチコミ返信に紐づくユーザー情報 */
export interface KuchikomiReplyUser {
  /** @example 2 */
  kuchikomiUserId: string | null
  /** @example 2 */
  kuchikomiEnterpriseId: string | null
  /** @example "サンプル太郎" */
  displayName: string
  /** @example "https://user.sample.jpg" */
  iconUrl: string
}

/** 企業 */
export type Enterprise = EnterpriseSimple & {
  businessCode: BusinessParentCode[] | null
  sdgsCategory: SDGsCategory[] | null
  /** 企業のランキング */
  enterpriseRank?: EnterpriseFieldRanking | null
  /** 企業のランキング */
  industryRank?: EnterpriseFieldRanking | null
  /** 企業のランキング */
  prefectureRank?: EnterpriseFieldRanking | null
  sdgsRank?: EnterpriseSDGsRanking[] | null
}

/** 企業 */
export interface EnterpriseSimple {
  /** @example 2 */
  id: string
  /** @example 2 */
  profile_id: string | null
  /** @example "サンプル株式会社" */
  name: string
  /** @example "サンプル株式会社です。" */
  description: string | null
  /** @example "サンプル姓" */
  delegateLastName: string | null
  /** @example "サンプル名" */
  delegateFirstName: string | null
  /** @example "サンプル役職" */
  delegatePosition: string | null
  /**
   * @format date-time:YYYY-MM-DD HH:mm:ss
   * @example "2024-03-01"
   */
  establishDate: string | null
  /** @example "000-0000" */
  postCode: string | null
  /** @example "サンプル住所0-00-0" */
  address: string | null
  prefecture: string | null
  /** @example "000-0000-000" */
  tel: string | null
  /**
   * - code: fishery_agriculture
   *   value: 水産・農林業
   * - code: mining
   *   value: 鉱業
   * - code: construction
   *   value: 建設業
   * - code: manufacturing
   *   value: 製造業
   * - code: electricity_gas
   *   value: 電気・ガス業
   * - code: transportation
   *   value: 倉庫・運輸関連業
   * - code: information_communication
   *   value: 情報通信
   * - code: commerce
   *   value: 商業（卸売業、小売業）
   * - code: finance_insurance
   *   value: 金融・保険業
   * - code: real_estate
   *   value: 不動産業
   * - code: food_accommodation
   *   value: 飲食店・宿泊業
   * - code: medical_welfare
   *   value: 医療・福祉
   * - code: education
   *   value: 教育・学習支援業
   * - code: service
   *   value: サービス業
   * - code: government
   *   value: 官公庁・地方自治体
   * - code: organization
   *   value: 財団法人・社団法人・宗教法人
   */
  industryParentCode: IndustryCode
  /**
   * - code: fishery_agriculture
   *   value: 水産・農林業
   * - code: mining
   *   value: 鉱業
   * - code: construction
   *   value: 建設業
   * - code: manufacturing
   *   value: 製造業
   * - code: electricity_gas
   *   value: 電気・ガス業
   * - code: transportation
   *   value: 倉庫・運輸関連業
   * - code: information_communication
   *   value: 情報通信
   * - code: commerce
   *   value: 商業（卸売業、小売業）
   * - code: finance_insurance
   *   value: 金融・保険業
   * - code: real_estate
   *   value: 不動産業
   * - code: food_accommodation
   *   value: 飲食店・宿泊業
   * - code: medical_welfare
   *   value: 医療・福祉
   * - code: education
   *   value: 教育・学習支援業
   * - code: service
   *   value: サービス業
   * - code: government
   *   value: 官公庁・地方自治体
   * - code: organization
   *   value: 財団法人・社団法人・宗教法人
   */
  industryChildCode: IndustryCode
  /**
   * - code: listed
   *   value: 上場企業
   * - code: local
   *   value: 地方企業
   * - code: foreign
   *   value: 外資系企業
   * - code: venture
   *   value: ベンチャー企業
   * - code: government
   *   value: 官公庁・団体
   */
  enterpriseCode: EnterpriseCode | null
  /** @example "https://sample-logo.png" */
  logoUrl: string
  /** @example "https://sample-official.png" */
  officialUrl?: string | null
  /** @example "https://sample-twitter.png" */
  twitterUrl?: string | null
  /** @example "https://sample-facebook.png" */
  facebookUrl?: string | null
  /** @example "https://sample-line.png" */
  lineUrl?: string | null
  /** @example "https://sample-tiktok.png" */
  tiktokUrl?: string | null
  /** @example 0 */
  evaluationAverage?: number | null
  /** @example 0 */
  kuchikomiCount: number | null
  /** @example 0 */
  publicFollowerNum: number | null
  /**
   * @default false
   * @example false
   */
  publicFollowerDisplayFlag: boolean
  /** @example 1000 */
  givedPointCount: number | null
  /** @example 1000 */
  holdPointCount: number | null
  /** @example true */
  followed: boolean
}

/** 企業 */
export interface KuchikomiEnterprise {
  /** @example 2 */
  id: string
  /** @example "サンプル株式会社" */
  name: string
  /** @example "https://sample-logo.png" */
  logoUrl: string
}

/** 企業の資料 */
export interface EnterpriseMaterial {
  /** SDGsの具体的な取り組み */
  activity: MaterialActivity | null
  /** 認証・評価の取得 */
  cert: MaterialCert | null
  /** TCFD提言に基づく情報開示 */
  tcfd: MaterialTcfd | null
  /** 温室効果ガス排出量 */
  ghg: MaterialGhg | null
}

/** SDGsの具体的な取り組み */
export interface MaterialActivity {
  /** @example "サンプル株式会社" */
  sourceSiteName: string | null
  /** @example "https://sample-site.com/quote" */
  sourceSiteUrl: string | null
  activityPosts: {
    /** @example "サンプルタイトル" */
    title: string
    /** @example "サンプルコンテンツ" */
    content: string | null
    /** @example "https://sample-image.png" */
    imageUrl: string
    sdgsCategories: SDGsCategory[]
  }[]
}

/** 認証・評価の取得 */
export interface MaterialCert {
  /** @example "サンプル株式会社" */
  sourceSiteName: string | null
  /** @example "https://sample-site.com/quote" */
  sourceSiteUrl: string | null
  certPosts: {
    /** @example "サンプル名前" */
    name: string
    /** @example "国内" */
    label: string
    /** @example "サンプル説明サンプル説明サンプル説明サンプル説明" */
    description: string | null
    /** @example "https://sample-image.png" */
    imageUrl: string
  }[]
}

/** TCFD提言に基づく情報開示 */
export interface MaterialTcfd {
  /** @example "サンプル株式会社" */
  sourceSiteName: string | null
  /** @example "https://sample-site.com/quote" */
  sourceSiteUrl: string | null
  /** @example "サンプルコンテンツ" */
  content: string | null
  /** @example "https://sample-image.png" */
  imageUrl: string
}

/** 温室効果ガス排出量 */
export interface MaterialGhg {
  ghgPosts: MaterialGhgPost[]
}

/** 温室効果ガス排出量の投稿 */
export interface MaterialGhgPost {
  /** @example 2024 */
  year: number
  /** @example "サンプル内容サンプル内容サンプル内容サンプル内容" */
  content: string | null
  /** @example "https://sample-image.png" */
  imageUrl: string
  /** @example "サンプル株式会社" */
  sourceSiteName: string | null
  /** @example "https://sample-site.com/quote" */
  sourceSiteUrl: string | null
}

/** 口コミ内容 */
export interface Kuchikomi {
  /** @example 2 */
  id: string
  /** クチコミに紐づくユーザー情報 */
  reviewer: KuchikomiUser
  /** @example 5 */
  evaluation: number
  /** @example 3000 */
  likeCount: number
  /** @default false */
  liked: boolean
  /** @default false */
  isOwn: boolean
  /** @default false */
  bookmarked: boolean
  /** 企業 */
  revieweeEnterprise: KuchikomiEnterprise
  /** @example "これはテストクチコミです。これはテストクチコミです。これはテストクチコミです。これはテストクチコミです。" */
  kuchikomi: string
  /**
   * - code: '1'
   *   value: 貧困をなくそう
   * - code: '2'
   *   value: 飢餓をゼロに
   * - code: '3'
   *   value: すべての人に健康と福祉を
   * - code: '4'
   *   value: 質の高い教育をみんなに
   * - code: '5'
   *   value: ジェンダー平等を実現しよう
   * - code: '6'
   *   value: 安全な水とトイレを世界中に
   * - code: '7'
   *   value: エネルギーをみんなに そしてクリーンに
   * - code: '8'
   *   value: 働きがいも経済成長も
   * - code: '9'
   *   value: 産業と技術革新の基盤をつくろう
   * - code: '10'
   *   value: 人や国の不平等をなくそう
   * - code: '11'
   *   value: 住み続けられるまちづくりを
   * - code: '12'
   *   value: つくる責任 つかう責任
   * - code: '13'
   *   value: 気候変動に具体的な対策を
   * - code: '14'
   *   value: 海の豊かさを守ろう
   * - code: '15'
   *   value: 陸の豊かさも守ろう
   * - code: '16'
   *   value: 平和と公正をすべての人に
   * - code: '17'
   *   value: パートナーシップで目標を達成しよう
   */
  category?: SDGsCategory | null
  /** @default [] */
  replies: KuchikomiReply[]
  /**
   * @format date-time:YYYY-MM-DD HH:mm:ss
   * @example "2024-03-01T00:00:00Z"
   */
  createdAt: string
}

/** 口コミに対する返信 */
export interface KuchikomiReply {
  /** @example 2 */
  id: string
  /** クチコミ返信に紐づくユーザー情報 */
  user: KuchikomiReplyUser
  /** @example "口コミに対する返信" */
  comment: string
  /**
   * @format date-time:YYYY-MM-DD HH:mm:ss
   * @example "2024-03-01T00:00:00Z"
   */
  createdAt: string
}

/** 企業のランキング */
export interface EnterpriseFieldRanking {
  /** @example 1 */
  rank: number
  /** ランキングの変動 */
  fluctuation: RankingFluctuation
  /** @example 1000 */
  total: number | null
}

/** SDGsランキング */
export interface EnterpriseSDGsRanking {
  /**
   * - code: '1'
   *   value: 貧困をなくそう
   * - code: '2'
   *   value: 飢餓をゼロに
   * - code: '3'
   *   value: すべての人に健康と福祉を
   * - code: '4'
   *   value: 質の高い教育をみんなに
   * - code: '5'
   *   value: ジェンダー平等を実現しよう
   * - code: '6'
   *   value: 安全な水とトイレを世界中に
   * - code: '7'
   *   value: エネルギーをみんなに そしてクリーンに
   * - code: '8'
   *   value: 働きがいも経済成長も
   * - code: '9'
   *   value: 産業と技術革新の基盤をつくろう
   * - code: '10'
   *   value: 人や国の不平等をなくそう
   * - code: '11'
   *   value: 住み続けられるまちづくりを
   * - code: '12'
   *   value: つくる責任 つかう責任
   * - code: '13'
   *   value: 気候変動に具体的な対策を
   * - code: '14'
   *   value: 海の豊かさを守ろう
   * - code: '15'
   *   value: 陸の豊かさも守ろう
   * - code: '16'
   *   value: 平和と公正をすべての人に
   * - code: '17'
   *   value: パートナーシップで目標を達成しよう
   */
  category: SDGsCategory
  /** @example 1 */
  rank: number
  /** @example 1000 */
  total: number | null
  /** ランキングの変動 */
  fluctuation: RankingFluctuation
}

/** 企業ランキング */
export interface EnterpriseRanking {
  category: string
  /** 企業 */
  enterprise: Enterprise
  /** @example 1 */
  rank: number
  /** @example 1000 */
  total: number | null
  /** ランキングの変動 */
  fluctuation: RankingFluctuation
}

/** ユーザーランキング */
export interface UserRanking {
  category: string
  /** ユーザー情報 */
  user: User
  /** @example 1 */
  rank: number
  /** @example 1000 */
  total: number | null
}

/** クチコミランキング */
export interface KuchikomiRanking {
  /** 口コミ内容 */
  kuchikomi: Kuchikomi
  /** @example 1 */
  rank: number
  /** @example 1000 */
  total: number | null
}

/** ランキングの期間 */
export enum RankingPeriod {
  Weekly = 'weekly',
  Yearly = 'yearly',
  All = 'all',
}

/** クチコミのランキングで使用する検索グループ */
export enum RankingEnterpriseStat {
  Evaluation = 'evaluation',
  KuchikomiCount = 'kuchikomi_count',
  FollwerCount = 'follwer_count',
}

/** 企業のランキングで使用する検索グループ */
export enum RankingKuchikomiStat {
  GoodCount = 'good_count',
  BookmarkCount = 'bookmark_count',
  CommentCount = 'comment_count',
}

/** ユーザーのランキングで使用する検索グループ */
export enum RankingUserStat {
  SendPointCount = 'send_point_count',
  KuchikomiCount = 'kuchikomi_count',
  FollwerCount = 'follwer_count',
}

/** ブックマークのソードで使用する検索グループ */
export enum BookmarkStat {
  NewArrival = 'new_arrival',
  GoodCount = 'good_count',
  CommentCount = 'comment_count',
}

/** ランキングの変動 */
export enum RankingFluctuation {
  Up = 'up',
  Stay = 'stay',
  Down = 'down',
  New = 'new',
}

/** トレンド */
export interface Trend {
  /**
   * - code: '1'
   *   value: 貧困をなくそう
   * - code: '2'
   *   value: 飢餓をゼロに
   * - code: '3'
   *   value: すべての人に健康と福祉を
   * - code: '4'
   *   value: 質の高い教育をみんなに
   * - code: '5'
   *   value: ジェンダー平等を実現しよう
   * - code: '6'
   *   value: 安全な水とトイレを世界中に
   * - code: '7'
   *   value: エネルギーをみんなに そしてクリーンに
   * - code: '8'
   *   value: 働きがいも経済成長も
   * - code: '9'
   *   value: 産業と技術革新の基盤をつくろう
   * - code: '10'
   *   value: 人や国の不平等をなくそう
   * - code: '11'
   *   value: 住み続けられるまちづくりを
   * - code: '12'
   *   value: つくる責任 つかう責任
   * - code: '13'
   *   value: 気候変動に具体的な対策を
   * - code: '14'
   *   value: 海の豊かさを守ろう
   * - code: '15'
   *   value: 陸の豊かさも守ろう
   * - code: '16'
   *   value: 平和と公正をすべての人に
   * - code: '17'
   *   value: パートナーシップで目標を達成しよう
   */
  sdgsCategory: SDGsCategory
  /** @example 100000 */
  postCount: number
  /** @example 1 */
  rank: number
}

/** お知らせ */
export interface Information {
  /** @example 2 */
  id: string
  /** @example "お知らせのタイトルです。お知らせのタイトルです。お知らせのタイトルです。" */
  title: string
  /** @example "お知らせの内容です。お知らせの内容です。お知らせの内容です。お知らせの内容です。" */
  content: string
  /** @example "https://sample.jpg" */
  image?: string | null
  /**
   * @format date:YYYY-MM-DD
   * @example "2024-03-01T00:00:00Z"
   */
  createdAt: string
}

/** 通知 */
export interface Notification {
  /**
   * 企業ID
   * @example "1"
   */
  kuchikomi_enterprise_id?: string | null
  /**
   * ユーザーID
   * @example "1"
   */
  kuchikomi_user_profile_id?: string | null
  /**
   * クチコミID
   * @example "1"
   */
  kuchikomi_id?: string | null
  /**
   * 通知メッセージ
   * @example "テスト太郎さんがあなたをフォローしました。"
   */
  message: string
  /**
   * 通知内容詳細
   * @example "テスト太郎さんがあなたをフォローしました。"
   */
  detail?: string | null
  /**
   * ユーザーアイコン
   * @example "https://sample.jpg"
   */
  userIcon: string
  /**
   * 通知内容をクリックした時の遷移先URL
   * @example "/kuchikomi/3"
   */
  url?: string | null
  /**
   * @format date:YYYY-MM-DD
   * @example "2024-03-01 00:00:00"
   */
  createdAt: string
}

/** メールアドレスログイン情報 */
export interface EmailLoginData {
  /** @example "test@test.test" */
  email: string
  /** @example "dflkgjhogioea9009" */
  password: string
}

/** お問い合わせ送信内容 */
export interface ContactRequestData {
  /** @example "サンプル株式会社" */
  enterpriseName: string | null
  /** @example "サンプル部" */
  departmentName: string | null
  /** @example "サンプル太郎" */
  name: string
  /** @example "test@test.com" */
  email: string
  /** @example "000-0000-0000" */
  tel: string | null
  /** @example "サンプル件名" */
  subject: string
  /** @example "サンプルメッセージ" */
  message: string
}

/** プレス */
export interface Press {
  /** @example 2 */
  id: string
  /** @example "https://sample.jpg" */
  mainImageUrl: string
  /** @example "sample press" */
  title: string
  /** @example "2024-03-03" */
  publishDate: string
  /** @example "https://sample.com" */
  url: string
}

/** FAQ */
export interface FAQ {
  /** @example 2 */
  id: string
  /** @example "sample" */
  category: string
  /** @example "sample question" */
  question: string
  /** @example "sample answer" */
  answer: string
}

/** Banner */
export interface Banner {
  /** @example "https://sample.jpg" */
  imageUrl: string
  /** @example "https://sample.com" */
  linkUrl: string
  bannerKb: 'sp_header' | 'pc_header' | 'pc_side'
}

/** チケット */
export interface Coupon {
  /** @example 2 */
  id: string
  /** @example "sample ticket" */
  title: string
  /** @example "sample description" */
  description: string
  /** @example "https://sample.jpg" */
  imageUrl: string
  /**
   * @format date-time:YYYY-MM-DD HH:mm:ss
   * @example "2024年3月1日"
   */
  expireDate: any
  /** @example 1000 */
  point: number
  /** クーポンのサイト・サービス */
  couponService: CouponService
}

/** クーポンのサイト・サービス */
export interface CouponService {
  /** @example 2 */
  id: string
  /** @example "sample service" */
  title: string
  /** @example "sample description" */
  description: string
  /** @example "https://sample.jpg" */
  imageUrl: string
  /** @example "https://sample.com" */
  siteUrl: string | null
}

export interface UserCoupon {
  /** @example 2 */
  couponId: string
  /** @example "sample ticket" */
  title: string
  /** @example "sample description" */
  description: string
  /** @example "https://sample.jpg" */
  imageUrl: string
  /**
   * @format date:YYYY-MM-DD
   * @example "2024-03-01"
   */
  expireDate: any
  /** @example "https://qrcode.png" */
  couponImageUrl: string
  /** @example 1000 */
  exchangedPoint: number
  /**
   * @format date-time:YYYY-MM-DD HH:mm:ss
   * @example "2024-03-01T00:00:00Z"
   */
  exchangedAt: string
  /** クーポンのサイト・サービス */
  couponService: CouponService
}

/** マイページトップ */
export interface MyPageTop {
  /**
   * - code: '1'
   *   value: Diamond
   */
  memberRank: MemberRank
  /** @example 1000 */
  givedPointCount: number
  /** @example 1000 */
  holdPointCount: number
  nextActions: NextAction[]
  /** @example 1000 */
  kuchikomiCount: number
  /** @example 1000 */
  kuchikomiEnterpriseCount: number
  /** @example 100 */
  kuchikomiPace: number
  /** @example 4.5 */
  evaluationAverage: number | null
  hints: Hint[]
  informations: Information[]
  notifications: Notification[]
}

/** Nextアクション */
export interface NextAction {
  /** @example 1 */
  index: number
  /** @example "プロフィール登録" */
  title: string
  /** @example "http://localhost/api/users/1/profile" */
  link: string
  /** @example false */
  complete: boolean
}

/** Nextアクション */
export interface Hint {
  /** @example "クチコミ・サス活ってなに？" */
  title: string
  /** @example "クチコミ・サス活とは。クチコミ・サス活とは。クチコミ・サス活とは。" */
  content: string
}

/**
 * アクセストークン
 * @example "Fghasdgkl9874r4#sgsl#dgag00ldkfgwe"
 */
export type AccessToken = string

/** 認証済みユーザー情報 */
export interface AuthenticatedUser {
  /**
   * ユーザー表示名
   * @example "サンプル太郎"
   */
  displayName: string
  /**
   * ユーザーアイコン画像
   * @example "http://sample.jpg"
   */
  iconUrl: string
  /**
   * 会員種別
   * @example "user"
   */
  licenseType: 'enterprise' | 'user'
  /**
   * クチコミ企業ID
   * @example null
   */
  kuchikomiEnterpriseId?: number | null
  /**
   * 有料プランかどうか
   * @example false
   */
  isPaidPlan: boolean
}

export interface SuccessResponse {
  /** @example "success" */
  result?: 'success'
}

/** バリデーションエラー(422エラー)の時に返却される共通のレスポンス */
export interface ValidationErrorResponse {
  /** @example "入力内容に誤りがあります。" */
  message?: string
  /** @example {"email":["メールアドレスは必須です。","メールアドレスの形式が正しくありません。"],"password":["パスワードは必須です。","パスワードは8文字以上で入力してください。"]} */
  errors?: {
    additionalProperties?: string[]
  }[]
}

/** 業務エラー(400エラー)の時に返却される共通のレスポンス */
export interface BusinessErrorResponse {
  /** @example "入力内容に誤りがあります。" */
  message?: string
}

/**
 * - code: listed
 *   value: 上場企業
 * - code: local
 *   value: 地方企業
 * - code: foreign
 *   value: 外資系企業
 * - code: venture
 *   value: ベンチャー企業
 * - code: government
 *   value: 官公庁・団体
 */
export interface EnterpriseCode {
  code: string
  value: string
}

/**
 * - code: fishery_agriculture
 *   value: 水産・農林業
 * - code: mining
 *   value: 鉱業
 * - code: construction
 *   value: 建設業
 * - code: manufacturing
 *   value: 製造業
 * - code: electricity_gas
 *   value: 電気・ガス業
 * - code: transportation
 *   value: 倉庫・運輸関連業
 * - code: information_communication
 *   value: 情報通信
 * - code: commerce
 *   value: 商業（卸売業、小売業）
 * - code: finance_insurance
 *   value: 金融・保険業
 * - code: real_estate
 *   value: 不動産業
 * - code: food_accommodation
 *   value: 飲食店・宿泊業
 * - code: medical_welfare
 *   value: 医療・福祉
 * - code: education
 *   value: 教育・学習支援業
 * - code: service
 *   value: サービス業
 * - code: government
 *   value: 官公庁・地方自治体
 * - code: organization
 *   value: 財団法人・社団法人・宗教法人
 */
export interface IndustryCode {
  code: string
  value: string
}

export interface OccupationCode {
  code: string
  value: string
}

/**
 * - code: employee
 *   value: 会社員
 * - code: public_officer
 *   value: 公務員
 * - code: self
 *   value: 自営業
 * - code: board_member
 *   value: 会社役員
 * - code: fleerance
 *   value: 自由業
 * - code: housewife
 *   value: 専業主婦（夫）
 * - code: student
 *   value: 学生
 * - code: part_time
 *   value: パート・アルバイト
 * - code: none
 *   value: 無職
 */
export interface GenderCode {
  code: string
  value: string
}

/**
 * - code: '1'
 *   value: 貧困をなくそう
 * - code: '2'
 *   value: 飢餓をゼロに
 * - code: '3'
 *   value: すべての人に健康と福祉を
 * - code: '4'
 *   value: 質の高い教育をみんなに
 * - code: '5'
 *   value: ジェンダー平等を実現しよう
 * - code: '6'
 *   value: 安全な水とトイレを世界中に
 * - code: '7'
 *   value: エネルギーをみんなに そしてクリーンに
 * - code: '8'
 *   value: 働きがいも経済成長も
 * - code: '9'
 *   value: 産業と技術革新の基盤をつくろう
 * - code: '10'
 *   value: 人や国の不平等をなくそう
 * - code: '11'
 *   value: 住み続けられるまちづくりを
 * - code: '12'
 *   value: つくる責任 つかう責任
 * - code: '13'
 *   value: 気候変動に具体的な対策を
 * - code: '14'
 *   value: 海の豊かさを守ろう
 * - code: '15'
 *   value: 陸の豊かさも守ろう
 * - code: '16'
 *   value: 平和と公正をすべての人に
 * - code: '17'
 *   value: パートナーシップで目標を達成しよう
 */
export interface SDGsCategory {
  code: string
  value: string
}

/**
 * - code: technology
 *   name: テクノロジー
 * - code: application
 *   name: アプリ
 * - code: entertaiment
 *   name: エンタメ
 * - code: vehicle
 *   name: 乗り物
 * - code: shopping
 *   name: ショッピング
 * - code: beauty
 *   name: ビューティー
 * - code: fashion
 *   name: ファッション
 * - code: food
 *   name: 食
 * - code: lifestyle
 *   name: ライフスタイル
 * - code: learning
 *   name: 学び
 * - code: business
 *   name: ビジネス
 * - code: medical
 *   name: 医療
 * - code: building
 *   name: 建物
 * - code: leisure
 *   name: レジャー
 * - code: sport
 *   name: スポーツ・アウトドア
 * - code: npo
 *   name: 非営利法人
 * - code: international
 *   name: 国際
 * - code: work
 *   name: ワークスタイル
 */
export interface BusinessParentCode {
  code: string
  value: string
  /**
   * - code: pc_device
   *   name: PC・デバイス
   * - code: smartphone
   *   name: スマートフォン
   * - code: network
   *   name: 回線
   * - code: camera
   *   name: カメラ
   * - code: home_appliances
   *   name: 白物・健康・美容家電
   * - code: cloud_computing
   *   name: クラウドコンピューティング
   * - code: security
   *   name: セキュリティ
   * - code: web_development
   *   name: Web・システム開発
   * - code: precision_instruments
   *   name: 精密機器
   * - code: online_services
   *   name: ネットサービス
   * - code: smartphone_app
   *   name: スマートフォンアプリ
   * - code: smartphone_game
   *   name: スマートフォンゲーム
   * - code: solutions
   *   name: ソリューション
   * - code: entertainment_music
   *   name: 芸能・音楽・テレビ・CM
   * - code: movies_plays_dvd
   *   name: 映画・演劇・DVD
   * - code: publishing_art_culture
   *   name: 出版・アート・カルチャー
   * - code: games_toys
   *   name: ゲーム・おもちゃ
   * - code: automotive_motorcycle
   *   name: 自動車・バイク
   * - code: transportation
   *   name: 交通
   * - code: online_shopping_delivery
   *   name: ネットショップ・通販
   * - code: department_store
   *   name: 百貨店・スーパー・コンビニ・ストア
   * - code: skincare_cosmetics
   *   name: スキンケア・化粧品・ヘア用品
   * - code: health_supplements
   *   name: ダイエット・健康食品・サプリメント
   * - code: fitness_healthcare
   *   name: フィットネス
   * - code: fashion_women
   *   name: レディースファッション
   * - code: fashion_men
   *   name: メンズファッション
   * - code: shoes_bags
   *   name: シューズ・バッグ
   * - code: jewelry_accessories
   *   name: ジュエリー・アクセサリー
   * - code: kids_baby_maternity
   *   name: キッズ・ベビー・マタニティ
   * - code: food_sweets_snacks
   *   name: フード・スイーツ・お菓子
   * - code: beverages
   *   name: 飲みもの
   * - code: restaurants
   *   name: 飲食店
   * - code: home_delivery
   *   name: 宅配
   * - code: fair_trade
   *   name: フェアトレード
   * - code: skincare_cosmetics
   *   name: スキンケア・化粧品・ヘア用品
   * - code: diet_health_supplements
   *   name: ダイエット・健康食品・サプリメント
   * - code: fitness_healthcare
   *   name: フィットネス・ヘルスケア
   * - code: interior_diy_miscellaneous
   *   name: インテリア・DIY・雑貨
   * - code: pets_pet_supplies
   *   name: ペット・ペット用品
   * - code: gifts_flowers
   *   name: ギフト・花
   * - code: romance_wedding
   *   name: 恋愛・ウェディング
   * - code: childbirth_childcare
   *   name: 出産・育児
   * - code: end_of_life_funeral
   *   name: 終活・葬儀
   * - code: job_hunting
   *   name: 就職・転職・人材派遣・アルバイト
   * - code: qualification_study
   *   name: 資格・留学・語学
   * - code: school_university
   *   name: 学校・大学
   * - code: cram_school
   *   name: 学習塾・予備校・通信教育
   * - code: childhood_education
   *   name: 保育・幼児教育
   * - code: management_consulting
   *   name: 経営・コンサルティング
   * - code: advertising
   *   name: 広告・宣伝・PR
   * - code: marketing
   *   name: マーケティング
   * - code: sales
   *   name: 営業
   * - code: finance
   *   name: 金融
   * - code: distribution
   *   name: 流通
   * - code: transportation_shipping
   *   name: 運輸・輸送
   * - code: materials_chemistry
   *   name: 素材・化学・エネルギー
   * - code: finance_accounting
   *   name: 財務・経理
   * - code: legal
   *   name: 法務・特許・知的財産
   * - code: startup
   *   name: スタートアップ
   * - code: medical_hospital
   *   name: 医療・病院
   * - code: pharmaceuticals
   *   name: 医薬・製薬
   * - code: welfare
   *   name: 福祉・介護・リハビリ
   * - code: housing_apartments
   *   name: 住宅・マンション
   * - code: commercial_facilities
   *   name: 商業施設・オフィスビル
   * - code: architecture_space_design
   *   name: 建築・空間デザイン
   * - code: construction
   *   name: 建設・土木
   * - code: hotels_inns
   *   name: ホテル・旅館
   * - code: travel_tourism
   *   name: 旅行・観光
   * - code: amusement_parks
   *   name: テーマパーク・遊園地
   * - code: baseball
   *   name: 野球
   * - code: soccer
   *   name: サッカー
   * - code: basketball
   *   name: バスケット
   * - code: volleyball
   *   name: バレー
   * - code: rugby
   *   name: ラグビー
   * - code: track_and_field
   *   name: 陸上
   * - code: swimming
   *   name: 水泳
   * - code: judo
   *   name: 柔道
   * - code: kendo
   *   name: 剣道
   * - code: martial_arts
   *   name: 武道・格闘技
   * - code: gymnastics
   *   name: 体操・新体操
   * - code: table_tennis
   *   name: 卓球
   * - code: tennis
   *   name: テニス
   * - code: badminton
   *   name: バトミントン
   * - code: golf
   *   name: ゴルフ
   * - code: skiing
   *   name: スキー・スノボード・スケート
   * - code: extreme_sports
   *   name: エクストリームスポーツ
   * - code: mountain_climbing
   *   name: 登山・クライミング
   * - code: walking
   *   name: ウォーキング
   * - code: running
   *   name: ランニング
   * - code: cycling
   *   name: サイクリング
   * - code: fishing
   *   name: 釣り
   * - code: camping
   *   name: キャンプ
   * - code: judo
   *   name: 柔道
   * - code: kendo
   *   name: 剣道
   * - code: martial_arts
   *   name: 武道・格闘技
   * - code: gymnastics
   *   name: 体操・新体操
   * - code: table_tennis
   *   name: 卓球
   * - code: tennis
   *   name: テニス
   * - code: badminton
   *   name: バトミントン
   * - code: golf
   *   name: ゴルフ
   * - code: skiing
   *   name: スキー・スノボード・スケート
   * - code: extreme_sports
   *   name: エクストリームスポーツ
   * - code: mountain_climbing
   *   name: 登山・クライミング
   * - code: walking
   *   name: ウォーキング
   * - code: running
   *   name: ランニング
   * - code: cycling
   *   name: サイクリング
   * - code: fishing
   *   name: 釣り
   * - code: camping
   *   name: キャンプ
   * - code: canoeing_SUP
   *   name: カヌー・SUP
   * - code: marine_air_sports
   *   name: マリン・スカイスポーツ
   * - code: nonprofit_organization
   *   name: NPO法人
   * - code: association
   *   name: 一般社団法人
   * - code: foundation
   *   name: 一般財団法人
   * - code: asia_oceania
   *   name: アジア・オセアニア
   * - code: europe
   *   name: ヨーロッパ
   * - code: north_america
   *   name: 北米
   * - code: latin_america
   *   name: 中南米
   * - code: africa
   *   name: アフリカ
   * - code: remote_work
   *   name: リモートワーク
   * - code: workation
   *   name: ワーケーション
   * - code: side_job
   *   name: 副業・兼業
   * - code: volunteer
   *   name: ボランティア
   * - code: agriculture
   *   name: 農林・水産
   */
  child: BusinessChildCode
}

/**
 * - code: pc_device
 *   name: PC・デバイス
 * - code: smartphone
 *   name: スマートフォン
 * - code: network
 *   name: 回線
 * - code: camera
 *   name: カメラ
 * - code: home_appliances
 *   name: 白物・健康・美容家電
 * - code: cloud_computing
 *   name: クラウドコンピューティング
 * - code: security
 *   name: セキュリティ
 * - code: web_development
 *   name: Web・システム開発
 * - code: precision_instruments
 *   name: 精密機器
 * - code: online_services
 *   name: ネットサービス
 * - code: smartphone_app
 *   name: スマートフォンアプリ
 * - code: smartphone_game
 *   name: スマートフォンゲーム
 * - code: solutions
 *   name: ソリューション
 * - code: entertainment_music
 *   name: 芸能・音楽・テレビ・CM
 * - code: movies_plays_dvd
 *   name: 映画・演劇・DVD
 * - code: publishing_art_culture
 *   name: 出版・アート・カルチャー
 * - code: games_toys
 *   name: ゲーム・おもちゃ
 * - code: automotive_motorcycle
 *   name: 自動車・バイク
 * - code: transportation
 *   name: 交通
 * - code: online_shopping_delivery
 *   name: ネットショップ・通販
 * - code: department_store
 *   name: 百貨店・スーパー・コンビニ・ストア
 * - code: skincare_cosmetics
 *   name: スキンケア・化粧品・ヘア用品
 * - code: health_supplements
 *   name: ダイエット・健康食品・サプリメント
 * - code: fitness_healthcare
 *   name: フィットネス
 * - code: fashion_women
 *   name: レディースファッション
 * - code: fashion_men
 *   name: メンズファッション
 * - code: shoes_bags
 *   name: シューズ・バッグ
 * - code: jewelry_accessories
 *   name: ジュエリー・アクセサリー
 * - code: kids_baby_maternity
 *   name: キッズ・ベビー・マタニティ
 * - code: food_sweets_snacks
 *   name: フード・スイーツ・お菓子
 * - code: beverages
 *   name: 飲みもの
 * - code: restaurants
 *   name: 飲食店
 * - code: home_delivery
 *   name: 宅配
 * - code: fair_trade
 *   name: フェアトレード
 * - code: skincare_cosmetics
 *   name: スキンケア・化粧品・ヘア用品
 * - code: diet_health_supplements
 *   name: ダイエット・健康食品・サプリメント
 * - code: fitness_healthcare
 *   name: フィットネス・ヘルスケア
 * - code: interior_diy_miscellaneous
 *   name: インテリア・DIY・雑貨
 * - code: pets_pet_supplies
 *   name: ペット・ペット用品
 * - code: gifts_flowers
 *   name: ギフト・花
 * - code: romance_wedding
 *   name: 恋愛・ウェディング
 * - code: childbirth_childcare
 *   name: 出産・育児
 * - code: end_of_life_funeral
 *   name: 終活・葬儀
 * - code: job_hunting
 *   name: 就職・転職・人材派遣・アルバイト
 * - code: qualification_study
 *   name: 資格・留学・語学
 * - code: school_university
 *   name: 学校・大学
 * - code: cram_school
 *   name: 学習塾・予備校・通信教育
 * - code: childhood_education
 *   name: 保育・幼児教育
 * - code: management_consulting
 *   name: 経営・コンサルティング
 * - code: advertising
 *   name: 広告・宣伝・PR
 * - code: marketing
 *   name: マーケティング
 * - code: sales
 *   name: 営業
 * - code: finance
 *   name: 金融
 * - code: distribution
 *   name: 流通
 * - code: transportation_shipping
 *   name: 運輸・輸送
 * - code: materials_chemistry
 *   name: 素材・化学・エネルギー
 * - code: finance_accounting
 *   name: 財務・経理
 * - code: legal
 *   name: 法務・特許・知的財産
 * - code: startup
 *   name: スタートアップ
 * - code: medical_hospital
 *   name: 医療・病院
 * - code: pharmaceuticals
 *   name: 医薬・製薬
 * - code: welfare
 *   name: 福祉・介護・リハビリ
 * - code: housing_apartments
 *   name: 住宅・マンション
 * - code: commercial_facilities
 *   name: 商業施設・オフィスビル
 * - code: architecture_space_design
 *   name: 建築・空間デザイン
 * - code: construction
 *   name: 建設・土木
 * - code: hotels_inns
 *   name: ホテル・旅館
 * - code: travel_tourism
 *   name: 旅行・観光
 * - code: amusement_parks
 *   name: テーマパーク・遊園地
 * - code: baseball
 *   name: 野球
 * - code: soccer
 *   name: サッカー
 * - code: basketball
 *   name: バスケット
 * - code: volleyball
 *   name: バレー
 * - code: rugby
 *   name: ラグビー
 * - code: track_and_field
 *   name: 陸上
 * - code: swimming
 *   name: 水泳
 * - code: judo
 *   name: 柔道
 * - code: kendo
 *   name: 剣道
 * - code: martial_arts
 *   name: 武道・格闘技
 * - code: gymnastics
 *   name: 体操・新体操
 * - code: table_tennis
 *   name: 卓球
 * - code: tennis
 *   name: テニス
 * - code: badminton
 *   name: バトミントン
 * - code: golf
 *   name: ゴルフ
 * - code: skiing
 *   name: スキー・スノボード・スケート
 * - code: extreme_sports
 *   name: エクストリームスポーツ
 * - code: mountain_climbing
 *   name: 登山・クライミング
 * - code: walking
 *   name: ウォーキング
 * - code: running
 *   name: ランニング
 * - code: cycling
 *   name: サイクリング
 * - code: fishing
 *   name: 釣り
 * - code: camping
 *   name: キャンプ
 * - code: judo
 *   name: 柔道
 * - code: kendo
 *   name: 剣道
 * - code: martial_arts
 *   name: 武道・格闘技
 * - code: gymnastics
 *   name: 体操・新体操
 * - code: table_tennis
 *   name: 卓球
 * - code: tennis
 *   name: テニス
 * - code: badminton
 *   name: バトミントン
 * - code: golf
 *   name: ゴルフ
 * - code: skiing
 *   name: スキー・スノボード・スケート
 * - code: extreme_sports
 *   name: エクストリームスポーツ
 * - code: mountain_climbing
 *   name: 登山・クライミング
 * - code: walking
 *   name: ウォーキング
 * - code: running
 *   name: ランニング
 * - code: cycling
 *   name: サイクリング
 * - code: fishing
 *   name: 釣り
 * - code: camping
 *   name: キャンプ
 * - code: canoeing_SUP
 *   name: カヌー・SUP
 * - code: marine_air_sports
 *   name: マリン・スカイスポーツ
 * - code: nonprofit_organization
 *   name: NPO法人
 * - code: association
 *   name: 一般社団法人
 * - code: foundation
 *   name: 一般財団法人
 * - code: asia_oceania
 *   name: アジア・オセアニア
 * - code: europe
 *   name: ヨーロッパ
 * - code: north_america
 *   name: 北米
 * - code: latin_america
 *   name: 中南米
 * - code: africa
 *   name: アフリカ
 * - code: remote_work
 *   name: リモートワーク
 * - code: workation
 *   name: ワーケーション
 * - code: side_job
 *   name: 副業・兼業
 * - code: volunteer
 *   name: ボランティア
 * - code: agriculture
 *   name: 農林・水産
 */
export interface BusinessChildCode {
  code: string
  value: string
}

/**
 * - code: factually_incorrect
 *   value: 事実と異なる
 * - code: defamation
 *   value: 誹謗中傷
 * - code: confidential_information
 *   value: 社外秘情報
 * - code: personal_information_leakage
 *   value: 個人情報漏洩
 * - code: unrelated_content
 *   value: 該当企業に関連のない内容
 * - code: other
 *   value: その他
 */
export interface KuchikomiReportCode {
  code: string
  value: string
}

/**
 * - code: '1'
 *   value: Diamond
 */
export interface MemberRank {
  code: 'platina' | 'gold' | 'silver' | 'bronze' | 'regular'
  value: string
}

export type QueryParamsType = Record<string | number, any>
export type ResponseFormat = keyof Omit<Body, 'body' | 'bodyUsed'>

export interface FullRequestParams extends Omit<RequestInit, 'body'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat
  /** request body */
  body?: unknown
  /** base url */
  baseUrl?: string
  /** request cancellation token */
  cancelToken?: CancelToken
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string
  baseApiParams?: Omit<RequestParams, 'baseUrl' | 'cancelToken' | 'signal'>
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void
  customFetch?: typeof fetch
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
  data: D
  error: E
}

type CancelToken = Symbol | string | number

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = 'http://localhost/api/kuchikomi'
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private abortControllers = new Map<CancelToken, AbortController>()
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams)

  private baseApiParams: RequestParams = {
    credentials: 'same-origin',
    headers: {},
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
  }

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig)
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key)
    return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key])
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key]
    return value.map((v: any) => this.encodeQueryParam(key, v)).join('&')
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {}
    const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key])
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join('&')
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery)
    return queryString ? `?${queryString}` : ''
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
    [ContentType.Text]: (input: any) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key]
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === 'object' && property !== null
              ? JSON.stringify(property)
              : `${property}`
        )
        return formData
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  }

  protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken)
      if (abortController) {
        return abortController.signal
      }
      return void 0
    }

    const abortController = new AbortController()
    this.abortControllers.set(cancelToken, abortController)
    return abortController.signal
  }

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken)

    if (abortController) {
      abortController.abort()
      this.abortControllers.delete(cancelToken)
    }
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const queryString = query && this.toQueryString(query)
    const payloadFormatter = this.contentFormatters[type || ContentType.Json]
    const responseFormat = format || requestParams.format

    return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {}),
      },
      signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
      body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>
      r.data = null as unknown as T
      r.error = null as unknown as E

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data
              } else {
                r.error = data
              }
              return r
            })
            .catch((e) => {
              r.error = e
              return r
            })

      if (cancelToken) {
        this.abortControllers.delete(cancelToken)
      }

      if (!response.ok) throw data
      return data
    })
  }
}

/**
 * @title SDGs クチコミ Lodge API
 * @version 0.0.2
 * @baseUrl http://localhost/api/kuchikomi
 *
 * WebApiの仕様を記載する。
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  ogp = {
    /**
     * No description
     *
     * @tags others
     * @name ImageCreate
     * @summary OGP画像を取得するAPI
     * @request POST:/ogp/image
     */
    imageCreate: (
      data: {
        /** @example "https://sample.com" */
        url: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "https://sample.jpg" */
          url: string
          /** @example "" */
          title: string
          /** @example "" */
          description: string | null
        },
        any
      >({
        path: `/ogp/image`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  upload = {
    /**
     * No description
     *
     * @tags upload
     * @name ImageCreate
     * @summary 画像をアップロードする
     * @request POST:/upload/image
     */
    imageCreate: (
      data: {
        /**
         * アップロードするファイル
         * @format binary
         */
        file?: File
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "https://sample.jpg" */
          url: string
        },
        any
      >({
        path: `/upload/image`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),
  }
  master = {
    /**
     * No description
     *
     * @tags master
     * @name EnterpriseCodeList
     * @summary 企業コードのマスタデータを取得するAPI
     * @request GET:/master/enterprise-code
     */
    enterpriseCodeList: (params: RequestParams = {}) =>
      this.request<EnterpriseCode[], any>({
        path: `/master/enterprise-code`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags master
     * @name IndustryCodeList
     * @summary 業種コードのマスタデータを取得するAPI
     * @request GET:/master/industry-code
     */
    industryCodeList: (params: RequestParams = {}) =>
      this.request<IndustryCode[], any>({
        path: `/master/industry-code`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags master
     * @name OccupationCodeList
     * @summary 職業コードのマスタデータを取得するAPI
     * @request GET:/master/occupation-code
     */
    occupationCodeList: (params: RequestParams = {}) =>
      this.request<OccupationCode[], any>({
        path: `/master/occupation-code`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags master
     * @name SdgsCategoryList
     * @summary SDGsカテゴリーのマスタデータを取得するAPI
     * @request GET:/master/sdgs-category
     */
    sdgsCategoryList: (params: RequestParams = {}) =>
      this.request<SDGsCategory[], any>({
        path: `/master/sdgs-category`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags master
     * @name BusinessCodeList
     * @summary ビジネスコードのマスタデータを取得するAPI
     * @request GET:/master/business-code
     */
    businessCodeList: (params: RequestParams = {}) =>
      this.request<BusinessParentCode[], any>({
        path: `/master/business-code`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags master
     * @name KuchikomiReportCodeList
     * @summary クチコミ違反報告のマスタデータを取得するAPI
     * @request GET:/master/kuchikomi-report-code
     */
    kuchikomiReportCodeList: (params: RequestParams = {}) =>
      this.request<KuchikomiReportCode[], any>({
        path: `/master/kuchikomi-report-code`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  auth = {
    /**
     * @description ログインに成功した場合ユーザー情報を返却する
     *
     * @tags authentication
     * @name LoginEmailCreate
     * @summary メールアドレスでログインするAPI
     * @request POST:/auth/login/email
     */
    loginEmailCreate: (data: EmailLoginData, params: RequestParams = {}) =>
      this.request<
        {
          /** 認証済みユーザー情報 */
          user: AuthenticatedUser
          /** アクセストークン */
          token: AccessToken
        },
        any
      >({
        path: `/auth/login/email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 現在フロントで保持している認証情報の有効期限が切れているか確認する。ヘッダーのアクセストークンを使って認証情報を確認する。
     *
     * @tags authentication
     * @name CheckCreate
     * @summary 認証情報確認API
     * @request POST:/auth/check
     */
    checkCreate: (params: RequestParams = {}) =>
      this.request<
        {
          /** 認証済みユーザー情報 */
          user: AuthenticatedUser
        },
        any
      >({
        path: `/auth/check`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  }
  follow = {
    /**
     * No description
     *
     * @tags follow
     * @name EnterpriseCreate
     * @summary 企業フォローAPI
     * @request POST:/follow/enterprise
     */
    enterpriseCreate: (
      data: {
        /** @example 2 */
        id: string
        /** @example true */
        set: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponse, any>({
        path: `/follow/enterprise`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags follow
     * @name UserCreate
     * @summary ユーザーフォローAPI
     * @request POST:/follow/user
     */
    userCreate: (
      data: {
        /** @example 2 */
        id: string
        /** @example true */
        set: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponse, any>({
        path: `/follow/user`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  ranking = {
    /**
     * No description
     *
     * @tags ranking
     * @name EnterpriseList
     * @summary 企業のランキングを取得するAPI
     * @request GET:/ranking/enterprise
     */
    enterpriseList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /** ランキングの期間 */
        period?: RankingPeriod
        /** クチコミのランキングで使用する検索グループ */
        stat?: RankingEnterpriseStat
        /** 検索する値のカテゴリー（全ての場合はundefined） */
        group?: string
        /** 検索する値のカテゴリー（全ての場合はundefined） */
        groupValue?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: EnterpriseRanking[]
        },
        any
      >({
        path: `/ranking/enterprise`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ranking
     * @name EnterpriseExpoList
     * @summary 万博企業のランキングを取得するAPI
     * @request GET:/ranking/enterprise/expo
     */
    enterpriseExpoList: (
      query?: {
        /** 万博のカテゴリー */
        category?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: EnterpriseRanking[]
        },
        any
      >({
        path: `/ranking/enterprise/expo`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ranking
     * @name UserList
     * @summary ユーザーのランキングを取得するAPI
     * @request GET:/ranking/user
     */
    userList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /** ランキングの期間 */
        period?: RankingPeriod
        /** ユーザーのランキングで使用する検索グループ */
        stat?: RankingUserStat
        /** 検索する値のカテゴリー（全ての場合はundefined） */
        group?: string
        /** 検索する値のカテゴリー（全ての場合はundefined） */
        groupValue?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: UserRanking[]
        },
        any
      >({
        path: `/ranking/user`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ranking
     * @name KuchikomiList
     * @summary クチコミのランキングを取得するAPI
     * @request GET:/ranking/kuchikomi
     */
    kuchikomiList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /** ランキングの期間 */
        period?: RankingPeriod
        /** 企業のランキングで使用する検索グループ */
        stat?: RankingKuchikomiStat
        /** 検索する値のカテゴリー（全ての場合はundefined） */
        group?: string
        /** 検索する値のカテゴリー（全ての場合はundefined） */
        groupValue?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: KuchikomiRanking[]
        },
        any
      >({
        path: `/ranking/kuchikomi`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags ranking
     * @name KuchikomiOwnList
     * @summary 自分のクチコミのランキングを取得するAPI
     * @request GET:/ranking/kuchikomi/own
     */
    kuchikomiOwnList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /** ランキングの期間 */
        period?: RankingPeriod
        /** 企業のランキングで使用する検索グループ */
        stat?: RankingKuchikomiStat
        /** 検索する値のカテゴリー（全ての場合はundefined） */
        group?: string
        /** 検索する値のカテゴリー（全ての場合はundefined） */
        groupValue?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: KuchikomiRanking[]
        },
        any
      >({
        path: `/ranking/kuchikomi/own`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  kuchikomis = {
    /**
     * No description
     *
     * @tags kuchikomi
     * @name KuchikomisList
     * @summary クチコミのリストを取得するAPI
     * @request GET:/kuchikomis
     */
    kuchikomisList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /**
         * キーワード
         * @default ""
         */
        keyword?: string
        /**
         * SDGsカテゴリー
         * @default ""
         * @example "REDUCEPOVERTY"
         */
        sdgsCategory?: string
        /**
         * 企業カテゴリー
         * @default "listed"
         */
        enterpriseCategory?: string
        /**
         * 産業
         * @default "mining"
         */
        industry?: string
        /**
         * 都道府県
         * @default ""
         */
        region?: string
        /**
         * 自分がブックマークしたクチコミのみを表示
         * @default false
         */
        isFilterBookmark?: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Kuchikomi[]
        },
        any
      >({
        path: `/kuchikomis`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description クチコミの新規登録
     *
     * @tags kuchikomi
     * @name KuchikomisCreate
     * @summary クチコミを作成するAPI
     * @request POST:/kuchikomis
     */
    kuchikomisCreate: (
      data: {
        /** @example 4 */
        enterpriseId: string
        /** @example 4 */
        evaluation: number
        /** @example "とても綺麗な会社です" */
        message: string
        /** @example "サンプル太郎" */
        sdgsCategory: string | null
        /** @example true */
        isFollow: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<Kuchikomi, any>({
        path: `/kuchikomis`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description クチコミの違反報告
     *
     * @tags kuchikomi
     * @name ReportCreate
     * @summary クチコミを違反報告するAPI
     * @request POST:/kuchikomis/report
     */
    reportCreate: (
      data: {
        /** @example 4 */
        kuchikomiId: string
        /** @example "other" */
        kuchikomiReportCode: string
        /** @example "事実と異なるクチコミが作成されています。お手数ですが削除をお願いしたいです。" */
        message: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "20220401-0001" */
          reportNumber: string
        },
        any
      >({
        path: `/kuchikomis/report`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description クチコミの取得
     *
     * @tags kuchikomi
     * @name DetailCreate
     * @summary 単一のクチコミを取得するAPI
     * @request POST:/kuchikomis/{kuchikomiId}/detail
     */
    detailCreate: (kuchikomiId: string, params: RequestParams = {}) =>
      this.request<
        {
          /** 口コミ内容 */
          kuchikomi: Kuchikomi
        },
        any
      >({
        path: `/kuchikomis/${kuchikomiId}/detail`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * @description クチコミの削除
     *
     * @tags kuchikomi
     * @name DeleteCreate
     * @summary クチコミを削除するAPI
     * @request POST:/kuchikomis/{kuchikomiId}/delete
     */
    deleteCreate: (kuchikomiId: string, params: RequestParams = {}) =>
      this.request<SuccessResponse, any>({
        path: `/kuchikomis/${kuchikomiId}/delete`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kuchikomi
     * @name LikeUpdate
     * @summary 口コミにいいね/削除するAPI
     * @request PUT:/kuchikomis/{kuchikomiId}/like
     */
    likeUpdate: (
      kuchikomiId: string,
      data: {
        /** @default 1 */
        point: number
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponse, any>({
        path: `/kuchikomis/${kuchikomiId}/like`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kuchikomi
     * @name BookmarkUpdate
     * @summary 口コミにブックマーク/削除するAPI
     * @request PUT:/kuchikomis/{kuchikomiId}/bookmark
     */
    bookmarkUpdate: (
      kuchikomiId: string,
      data: {
        /** @default true */
        set: boolean
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example true */
          bookmarked: boolean
        },
        any
      >({
        path: `/kuchikomis/${kuchikomiId}/bookmark`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kuchikomi
     * @name ReplyUpdate
     * @summary 口コミに返信するAPI
     * @request PUT:/kuchikomis/{kuchikomiId}/reply
     */
    replyUpdate: (
      kuchikomiId: string,
      data: {
        /** @example "返信メッセージ" */
        message: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** 口コミに対する返信 */
          reply: KuchikomiReply
        },
        any
      >({
        path: `/kuchikomis/${kuchikomiId}/reply`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kuchikomi
     * @name GetKuchikomis
     * @summary 新着の口コミ一覧を取得するAPI
     * @request GET:/kuchikomis/new
     */
    getKuchikomis: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Kuchikomi[]
        },
        any
      >({
        path: `/kuchikomis/new`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags kuchikomi
     * @name PopularList
     * @summary 人気の口コミ一覧を取得するAPI
     * @request GET:/kuchikomis/popular
     */
    popularList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /** ユーザーID */
        userId?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Kuchikomi[]
        },
        any
      >({
        path: `/kuchikomis/popular`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  enterprises = {
    /**
     * No description
     *
     * @tags enterprise
     * @name EnterprisesList
     * @summary 企業のリストを取得するAPI
     * @request GET:/enterprises
     */
    enterprisesList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /**
         * キーワード
         * @default ""
         */
        keyword?: string
        /**
         * SDGsカテゴリー
         * @default ""
         */
        sdgsCategory?: string
        /**
         * 企業カテゴリー
         * @default ""
         */
        enterpriseCategory?: string
        /**
         * 産業
         * @default ""
         */
        industry?: string
        /**
         * 都道府県
         * @default ""
         */
        region?: string
        /**
         * 検索順位ソート
         * @default ""
         */
        search_order?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Enterprise[]
        },
        any
      >({
        path: `/enterprises`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description 企業が会員登録する前にメールを送信する
     *
     * @tags enterprise
     * @name RegisterMailCreate
     * @summary 企業登録前のメール送信API
     * @request POST:/enterprises/register/mail
     */
    registerMailCreate: (
      data: {
        /**
         * メールアドレス
         * @example "enterprise@enterprise.com"
         */
        email: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponse, any>({
        path: `/enterprises/register/mail`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 企業会員登録URLのトークンをチェックする
     *
     * @tags enterprise
     * @name RegisterTokenCheckCreate
     * @summary 企業会員登録URLのトークンをチェックするAPI
     * @request POST:/enterprises/register/token/check
     */
    registerTokenCheckCreate: (
      data: {
        /**
         * トークン
         * @example "AlknmcaprDSDFsdvmcwafoSwqg"
         */
        token: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "test@test.com" */
          email: string
        },
        any
      >({
        path: `/enterprises/register/token/check`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description 企業の新規登録
     *
     * @tags enterprise
     * @name RegisterCreate
     * @summary 企業を作成するAPI
     * @request POST:/enterprises/register
     */
    registerCreate: (
      data: {
        /** @example "トークン" */
        token: string
        /** @example "サンプル株式会社" */
        name: string
        /** @example "サンプル株式会社です。" */
        description?: string
        /** @example "サンプル" */
        delegateLastName: string
        /** @example "サンプル" */
        delegateFirstName: string
        /** @example "サンプル" */
        delegatePosition: string
        /** @example "サンプル" */
        responsiblePersonName: string
        /** @example "2024-04-01" */
        establishDate: string
        /** @example "東京都" */
        prefecture: string
        /** @example "000-0000" */
        postCode: string
        /** @example "東京都中央区0-00-0" */
        address: string
        /** @example "000-0000-000" */
        tel: string
        /** @example "000-0000" */
        stockCode?: string
        /** @example 1000000 */
        capitalPrice?: number | null
        /** @example 1000 */
        employeeNum?: number | null
        /** @example "sample" */
        industryParentCode: string
        /** @example "sample" */
        industryChildCode: string
        /** @example "sample" */
        enterpriseCode: string
        businessCode: string[]
        businessChildCode: string[]
        sdgsCategory: string[]
        /** @example "https://sample.jpg" */
        logoUrl: string
        /** @example "https://sample.jp" */
        officialUrl?: string
        /** @example "https://twitter.sample.jp" */
        twitterUrl?: string
        /** @example "https://facebook.sample.jp" */
        facebookUrl?: string
        /** @example "https://line.sample.jp" */
        lineUrl?: string
        /** @example "https://tiktok.sample.jp" */
        tiktokUrl?: string
        /** @example true */
        publicDisplayFlag: boolean
        /** @example "sample@sample.jp" */
        email: string
        /** @example "sample000A!" */
        password: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponse, any>({
        path: `/enterprises/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags enterprise
     * @name TopSliderList
     * @summary トップページスライダーに表示する企業情報とクチコミを取得するAPI
     * @request GET:/enterprises/top-slider
     */
    topSliderList: (params: RequestParams = {}) =>
      this.request<
        {
          /** 企業 */
          enterprise: EnterpriseSimple
          kuchikomis: Kuchikomi[]
        }[],
        any
      >({
        path: `/enterprises/top-slider`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags enterprise
     * @name EnterprisesDetail
     * @summary 企業詳細を取得するAPI
     * @request GET:/enterprises/{enterpriseId}
     */
    enterprisesDetail: (enterpriseId: string, params: RequestParams = {}) =>
      this.request<Enterprise, any>({
        path: `/enterprises/${enterpriseId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags enterprise
     * @name MaterialDetail
     * @summary 企業の資料詳細を取得するAPI
     * @request GET:/enterprises/{enterpriseId}/material
     */
    materialDetail: (enterpriseId: string, params: RequestParams = {}) =>
      this.request<EnterpriseMaterial, any>({
        path: `/enterprises/${enterpriseId}/material`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags enterprise
     * @name KuchikomisDetail
     * @summary 企業単位の口コミのリストを取得するAPI
     * @request GET:/enterprises/{enterpriseId}/kuchikomis
     */
    kuchikomisDetail: (
      enterpriseId: string,
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Kuchikomi[]
        },
        any
      >({
        path: `/enterprises/${enterpriseId}/kuchikomis`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags enterprise
     * @name PressesDetail
     * @summary 企業のプレスリリースのリストを取得するAPI
     * @request GET:/enterprises/{enterpriseId}/presses
     */
    pressesDetail: (
      enterpriseId: string,
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Press[]
        },
        any
      >({
        path: `/enterprises/${enterpriseId}/presses`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags enterprise
     * @name RelatedEnterprisesDetail
     * @summary 企業の関連のリストを取得するAPI
     * @request GET:/enterprises/{enterpriseId}/related-enterprises
     */
    relatedEnterprisesDetail: (
      enterpriseId: string,
      query?: {
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: Enterprise[]
        },
        any
      >({
        path: `/enterprises/${enterpriseId}/related-enterprises`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  users = {
    /**
     * No description
     *
     * @tags user
     * @name UsersList
     * @summary ユーザーのリストを取得するAPI
     * @request GET:/users
     */
    usersList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /**
         * キーワード
         * @default ""
         */
        keyword?: string
        /**
         * SDGsカテゴリー
         * @default ""
         */
        sdgsCategory?: string
        /**
         * 職種
         * @default ""
         */
        occupation?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: User[]
        },
        any
      >({
        path: `/users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * @description ユーザーの新規登録
     *
     * @tags user
     * @name RegisterCreate
     * @summary ユーザーを作成するAPI
     * @request POST:/users/register
     */
    registerCreate: (
      data: {
        /** @example "テスト" */
        lastName?: string | null
        /** @example "テスト" */
        firstName?: string | null
        /** @example "サンプル太郎" */
        displayName: string
        /** @example "male" */
        gender?: string | null
        /** @example "2000-01-01" */
        birthDay?: string | null
        /** @example "https://user.test.jpg" */
        iconUrl?: string | null
        /** @example "sample@sample.sample" */
        email: string
        /** @example "sampleSample999" */
        password: string
        /** @example "sampleSample999" */
        password_confirmation: string
        /**
         * @default ""
         * @example "私はテストプロフィールです。私はテストプロフィールです。私はテストプロフィールです。私はテストプロフィールです。"
         */
        profile?: string | null
        /** @example "東京都" */
        prefecture?: string | null
        /** @example "sample" */
        occupationCode?: string | null
        /**
         * @default ""
         * @example "キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。"
         */
        catchCopy?: string | null
        interestingSDGs?: (string | null)[]
        /** @example "https://sample.jp" */
        homepageUrl?: string | null
        /** @example "https://twitter.sample.jp" */
        twitterUrl?: string | null
        /** @example "https://facebook.sample.jp" */
        facebookUrl?: string | null
        /** @example "https://instagram.sample.jp" */
        instagramUrl?: string | null
        /** @example "https://tiktok.sample.jp" */
        tiktokUrl?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** 認証済みユーザー情報 */
          user: AuthenticatedUser
          /** アクセストークン */
          token: AccessToken
        },
        any
      >({
        path: `/users/register`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UpdateUpdate
     * @summary ユーザー情報を更新するAPI
     * @request PUT:/users/update
     */
    updateUpdate: (
      data: {
        /** @example "テスト" */
        lastName: string
        /** @example "テスト" */
        firstName: string
        /** @example "サンプル太郎" */
        displayName: string
        /** @example "2000-01-01" */
        birthDay: string
        /** @example "https://user.test.jpg" */
        iconUrl: string
        /**
         * @default ""
         * @example "私はテストプロフィールです。私はテストプロフィールです。私はテストプロフィールです。私はテストプロフィールです。"
         */
        profile: string
        /**
         * @default ""
         * @example "キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。キャッチコピー。"
         */
        catchCopy: string
        /** @example "employee" */
        occupationCode: string
        /** @example "male" */
        gender: string
        /** @example "sample@sample.sample" */
        email: string
        /** @example "東京都" */
        prefecture: string
        interestingSDGs: string[]
        /** @example "https://sample.jp" */
        homepageUrl: string
        /** @example "https://twitter.sample.jp" */
        twitterUrl: string
        /** @example "https://facebook.sample.jp" */
        facebookUrl: string
        /** @example "https://instagram.sample.jp" */
        instagramUrl: string
        /** @example "https://tiktok.sample.jp" */
        tiktokUrl: string
        /** @example false */
        isPasswordChange: boolean
        password: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponse, any>({
        path: `/users/update`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UsersDetail
     * @summary ユーザー詳細を取得するAPI
     * @request GET:/users/{userId}
     */
    usersDetail: (userId: string, params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/users/${userId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name FollowsDetail
     * @summary フォローしているユーザーのリストを取得するAPI
     * @request GET:/users/{userId}/follows
     */
    followsDetail: (
      userId: string,
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: User[]
        },
        any
      >({
        path: `/users/${userId}/follows`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name FollowersDetail
     * @summary フォロワーのリストを取得するAPI
     * @request GET:/users/{userId}/followers
     */
    followersDetail: (
      userId: string,
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: User[]
        },
        any
      >({
        path: `/users/${userId}/followers`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name EnterprisesDetail
     * @summary クチコミをした企業のリストを取得するAPI
     * @request GET:/users/{userId}/enterprises
     */
    enterprisesDetail: (
      userId: string,
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<Enterprise[], any>({
        path: `/users/${userId}/enterprises`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name EnterpriseFollowsDetail
     * @summary フォローしている企業のリストを取得するAPI
     * @request GET:/users/{userId}/enterprise-follows
     */
    enterpriseFollowsDetail: (
      userId: string,
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Enterprise[]
        },
        any
      >({
        path: `/users/${userId}/enterprise-follows`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name KuchikomisDetail
     * @summary ユーザーのクチコミを取得するAPI
     * @request GET:/users/{userId}/kuchikomis
     */
    kuchikomisDetail: (
      userId: string,
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Kuchikomi[]
        },
        any
      >({
        path: `/users/${userId}/kuchikomis`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name KuchikomisPopularDetail
     * @summary ユーザーの人気クチコミを取得するAPI
     * @request GET:/users/{userId}/kuchikomis/popular
     */
    kuchikomisPopularDetail: (
      userId: string,
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Kuchikomi[]
        },
        any
      >({
        path: `/users/${userId}/kuchikomis/popular`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name RelatedUsersDetail
     * @summary 関連ユーザーを取得するAPI
     * @request GET:/users/{userId}/related-users
     */
    relatedUsersDetail: (
      userId: string,
      query?: {
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          data: User[]
        },
        any
      >({
        path: `/users/${userId}/related-users`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name KeywordsDetail
     * @summary ユーザーに関連したキーワードリストを取得するAPI
     * @request GET:/users/{userId}/keywords
     */
    keywordsDetail: (
      userId: string,
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          /** @example [{"keyword":"sample"}] */
          data: {
            keyword?: string
          }[]
        },
        any
      >({
        path: `/users/${userId}/keywords`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name DeleteCreate
     * @summary ユーザーを削除するAPI
     * @request POST:/users/delete
     */
    deleteCreate: (params: RequestParams = {}) =>
      this.request<SuccessResponse, any>({
        path: `/users/delete`,
        method: 'POST',
        format: 'json',
        ...params,
      }),
  }
  trends = {
    /**
     * No description
     *
     * @tags trend
     * @name TrendsList
     * @summary トレンドのリストを取得するAPI
     * @request GET:/trends
     */
    trendsList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Trend[]
        },
        any
      >({
        path: `/trends`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  keywords = {
    /**
     * No description
     *
     * @tags keyword
     * @name AttentionList
     * @summary 注目キーワードリストを取得するAPI
     * @request GET:/keywords/attention
     */
    attentionList: (
      query?: {
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /**
         * ユーザーID（nullの場合はユーザー関係なく取得）
         * @default null
         */
        userId?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example [{"keyword":"sample"}] */
          data: {
            keyword?: string
          }[]
        },
        any
      >({
        path: `/keywords/attention`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  informations = {
    /**
     * No description
     *
     * @tags informations
     * @name GetInformations
     * @summary 新着お知らせを取得するAPI
     * @request GET:/informations/new
     */
    getInformations: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          /** @example [{"id":"2fbf1966-094d-2793-09b5-d5daf1aed8d1","title":"test","content":"test test test test test test test test","createdAt":"2024-03-01T00:00:00Z"}] */
          data: Information[]
        },
        any
      >({
        path: `/informations/new`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags informations
     * @name InformationsList
     * @summary お知らせ一覧を取得するAPI
     * @request GET:/informations
     */
    informationsList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          /** @example [{"id":"2fbf1966-094d-2793-09b5-d5daf1aed8d1","title":"test","content":"test test test test test test test test","createdAt":"2024-03-01T00:00:00Z"}] */
          data: Information[]
        },
        any
      >({
        path: `/informations`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags informations
     * @name InformationsDetail
     * @summary お知らせ詳細を取得するAPI
     * @request GET:/informations/{informationId}
     */
    informationsDetail: (informationId: string, params: RequestParams = {}) =>
      this.request<Information, any>({
        path: `/informations/${informationId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  notification = {
    /**
     * No description
     *
     * @tags notifications
     * @name NotificationList
     * @summary 新着通知を取得するAPI
     * @request GET:/notification
     */
    notificationList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Notification[]
        },
        any
      >({
        path: `/notification`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  faqs = {
    /**
     * No description
     *
     * @tags faqs
     * @name FaqsList
     * @summary FAQのリストを取得するAPI
     * @request GET:/faqs
     */
    faqsList: (params: RequestParams = {}) =>
      this.request<FAQ[], any>({
        path: `/faqs`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  banners = {
    /**
     * No description
     *
     * @tags banners
     * @name BannersList
     * @summary バナーのリストを取得するAPI
     * @request GET:/banners
     */
    bannersList: (
      query?: {
        /**
         * バナー種別, nullの場合は全て取得する
         * @default null
         */
        bannerKb?: 'sp_header' | 'pc_header' | 'pc_side'
      },
      params: RequestParams = {}
    ) =>
      this.request<Banner[], any>({
        path: `/banners`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  mypage = {
    /**
     * No description
     *
     * @tags mypage
     * @name UserList
     * @summary マイページのユーザーを取得するAPI
     * @request GET:/mypage/user
     */
    userList: (params: RequestParams = {}) =>
      this.request<User, any>({
        path: `/mypage/user`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mypage
     * @name RegisterdUserInfoList
     * @summary 登録されているユーザー情報を取得するAPI
     * @request GET:/mypage/registerd-user-info
     */
    registerdUserInfoList: (params: RequestParams = {}) =>
      this.request<RegisterdUserInfo, any>({
        path: `/mypage/registerd-user-info`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mypage
     * @name GetMypage
     * @summary マイページトップのデータを取得するAPI
     * @request GET:/mypage/top
     */
    getMypage: (params: RequestParams = {}) =>
      this.request<MyPageTop, any>({
        path: `/mypage/top`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mypage
     * @name TokenDashboardCreate
     * @summary ダッシュボードに遷移するのに必要なトークン情報を取得する
     * @request POST:/mypage/token/dashboard
     */
    tokenDashboardCreate: (
      data: {
        /** @example "XTRcetztgGCDOy7FuNAs" */
        key: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "XTRcetztgGCDOy7FuNAs" */
          token: string
          /** @example "XTRcetztgGCDOy7FuNAs" */
          userId: string
        },
        any
      >({
        path: `/mypage/token/dashboard`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mypage
     * @name BookmarkKuchikomiList
     * @summary ブックマークしたクチコミデータを取得するAPI
     * @request GET:/mypage/bookmark-kuchikomi
     */
    bookmarkKuchikomiList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /**
         * SDGsカテゴリー
         * @example "REDUCEPOVERTY"
         */
        sdgsCategory?: string | null
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Kuchikomi[]
        },
        any
      >({
        path: `/mypage/bookmark-kuchikomi`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mypage
     * @name LikeKuchikomiList
     * @summary いいねしたクチコミデータを取得するAPI
     * @request GET:/mypage/like-kuchikomi
     */
    likeKuchikomiList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Kuchikomi[]
        },
        any
      >({
        path: `/mypage/like-kuchikomi`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags mypage
     * @name NotificationList
     * @summary 通知を取得するAPI
     * @request GET:/mypage/notification
     */
    notificationList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Notification[]
        },
        any
      >({
        path: `/mypage/notification`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  coupons = {
    /**
     * No description
     *
     * @tags coupon
     * @name CouponsList
     * @summary クーポンの一覧を取得するAPI
     * @request GET:/coupons
     */
    couponsList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /**
         * 検索ソート順
         * @default "new"
         */
        search_order?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: Coupon[]
        },
        any
      >({
        path: `/coupons`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags coupon
     * @name ExchangeConfirmDetail
     * @summary クーポン購入確認API
     * @request GET:/coupons/{couponId}/exchange/confirm
     */
    exchangeConfirmDetail: (couponId: string, params: RequestParams = {}) =>
      this.request<
        {
          /** チケット */
          coupon: Coupon
          /** @example 1000 */
          currentPoint: number
          /** @example 1000 */
          remainPoint: number | null
          /** @example "ポイントが足りません。" */
          errorMessage: string | null
        },
        any
      >({
        path: `/coupons/${couponId}/exchange/confirm`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags coupon
     * @name ExchangeCreate
     * @summary クーポン購入API
     * @request POST:/coupons/{couponId}/exchange
     */
    exchangeCreate: (couponId: string, params: RequestParams = {}) =>
      this.request<
        {
          userCoupon: UserCoupon
        },
        any
      >({
        path: `/coupons/${couponId}/exchange`,
        method: 'POST',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags coupon
     * @name ExchangedList
     * @summary 購入済みクーポンの一覧を取得するAPI
     * @request GET:/coupons/exchanged
     */
    exchangedList: (
      query?: {
        /**
         * 取得開始位置
         * @default 0
         */
        cursor?: number
        /**
         * 取得件数
         * @default 100
         */
        limit?: number
        /**
         * 検索ソート順
         * @default "new"
         */
        search_order?: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example 1000 */
          total: number
          data: UserCoupon[]
        },
        any
      >({
        path: `/coupons/exchanged`,
        method: 'GET',
        query: query,
        format: 'json',
        ...params,
      }),
  }
  contacts = {
    /**
     * @description お問い合わせ内容を確認して、問題ある場合はエラーを返す
     *
     * @tags contacts
     * @name ConfirmCreate
     * @summary お問い合わせを送信内容を確認するAPI
     * @request POST:/contacts/confirm
     */
    confirmCreate: (data: ContactRequestData, params: RequestParams = {}) =>
      this.request<SuccessResponse, any>({
        path: `/contacts/confirm`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description お問い合わせを送信
     *
     * @tags contacts
     * @name SendCreate
     * @summary お問い合わせを送信するAPI
     * @request POST:/contacts/send
     */
    sendCreate: (data: ContactRequestData, params: RequestParams = {}) =>
      this.request<SuccessResponse, any>({
        path: `/contacts/send`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
  passwords = {
    /**
     * @description 入力されたメールアドレスに引き当たるクチコミユーザーまたは紐付け済みの企業ユーザーがいれば、入力メールアドレス宛にパスワードリセットを行うURLが入ったメールを送信する
     *
     * @tags passwords
     * @name ResetSendCreate
     * @summary パスワードリセットメールを送信するAPI
     * @request POST:/passwords/reset/send
     */
    resetSendCreate: (
      data: {
        /** @example "test@test.com" */
        email: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "test@test.com" */
          email: string
        },
        any
      >({
        path: `/passwords/reset/send`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description トークンからメールアドレスを取得する
     *
     * @tags passwords
     * @name GetEmailCreate
     * @summary トークンからメールアドレスを取得するAPI
     * @request POST:/passwords/get/email
     */
    getEmailCreate: (
      data: {
        /** @example "XTRcetztgGCDOy7FuNAs" */
        token: string
      },
      params: RequestParams = {}
    ) =>
      this.request<
        {
          /** @example "test@test.com" */
          email: string
        },
        any
      >({
        path: `/passwords/get/email`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @description パスワードリセットを実行するAPI
     *
     * @tags passwords
     * @name ResetStoreCreate
     * @summary パスワードリセットAPI
     * @request POST:/passwords/reset/store
     */
    resetStoreCreate: (
      data: {
        /**
         * トークン
         * @example "test@test.com"
         */
        token: string
        /**
         * メールアドレス
         * @example "test@test.com"
         */
        email: string
        /**
         * パスワード
         * @example "xxxxxxxx"
         */
        password: string
        /**
         * パスワード再入力
         * @example "XTRcetztgGCDOy7FuNAs"
         */
        password_confirmation: string
      },
      params: RequestParams = {}
    ) =>
      this.request<SuccessResponse, any>({
        path: `/passwords/reset/store`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),
  }
}
