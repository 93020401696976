export const formatDate = (date: Date): string => {
  const year = date.getFullYear()
  // getMonth() は 0 から始まるので、1 を加えて修正する。
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')

  return `${year}/${month}/${day}`
}

export const getKuchikomiDate = (date: string, isKuchikomiCard = true) => {
  // クチコミリリース時は日付を表示しない
  if (isKuchikomiCard) {
    return ''
  }

  const currentDate = new Date()
  const dateObj = new Date(date)
  const dateDiffMiliSec = currentDate.getTime() - dateObj.getTime()
  const oneHourMiliSec = 1000 * 60 * 60
  const oneDayMiliSec = 1000 * 60 * 60 * 24

  if (dateDiffMiliSec < oneHourMiliSec) {
    return `${String(Math.ceil(dateDiffMiliSec / 1000 / 60))}分前`
  }

  if (dateDiffMiliSec < oneDayMiliSec) {
    return `${String(Math.ceil(dateDiffMiliSec / 1000 / 60 / 60))}時間前`
  }

  return formatDate(dateObj)
}
