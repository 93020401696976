import React, { useEffect, useState } from 'react'
import MyPageTemplate from './components/templates/mypage_template'
import useUser from './hooks/use_user'
import MemberRankCard from './components/cards/member_rank_card'
import PointCard from './components/cards/point_card'
import NextActionCard from './components/cards/next_action_card'
import useTop from './hooks/use_top'
import KuchikomiSasukatsuCard from './components/cards/kuchikomi_sasukatsu_card'
import HintCard from './components/cards/hint_card'
import InformationCard from './components/cards/information_card'
import Head2 from '../../components/heads/head2'
import KuchikomiCard from '../../components/card/kuchikomi_card'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import useUserKuchikomi from '../../hooks/use_user_kuchikomi'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import LoadingSpinner from '../../components/loading/loading_spinner'
import NotificationCard from './components/cards/notification_card'

const MyPageTop = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [user, userLoading] = useUser()
  const [userId, setUserId] = useState('')
  const [top, topLoading] = useTop()
  const [kuchikomis, size, currentCursor, kuchikomiLoading, kuchikomiHandler] =
    useUserKuchikomi({
      type: 'LIST',
    })
  useEffect(() => {
    if (user) {
      setUserId(user.id)
      kuchikomiHandler.load(user?.id)
    }
  }, [user])

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const nextActionCount = top?.nextActions.length

  const kuchikomiElems = (
    <div>
      <div className="mb-4 sm:px-4" ref={IndexSectionRef}>
        <Head2
          title="クチコミ一覧"
          subTitle={
            kuchikomis.length > 0
              ? `全件${String(size)}中 ${currentCursor + 1}~${
                  currentCursor + kuchikomis.length
                }件`
              : ''
          }
        />
      </div>
      <div>
        {!kuchikomiLoading ? (
          <div className="overflow-hidden rounded-lg">
            {kuchikomis.length > 0 ? (
              kuchikomis.map((item, index) => {
                return (
                  <div key={`kuchikomi-${String(index)}`}>
                    <KuchikomiCard
                      kuchikomi={item}
                      borderBottom={index !== kuchikomis.length - 1}
                      isSp={isSp}
                      sendLike={kuchikomiHandler.like}
                      onClickBookmark={kuchikomiHandler.bookmark}
                      sendReply={kuchikomiHandler.reply}
                      deleteKuchikomi={kuchikomiHandler.delete}
                    />
                  </div>
                )
              })
            ) : (
              <div className="w-full">
                <p className="text-center text-main-lightGray">
                  クチコミがありません
                </p>
              </div>
            )}
          </div>
        ) : (
          <LoadingSpinner className="mx-auto mb-16" />
        )}
      </div>
    </div>
  )

  const paginationCursor =
    kuchikomis.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        numRange={3}
        next={() => {
          scrollToIndexSection()
          kuchikomiHandler.load(userId, currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToIndexSection()
          kuchikomiHandler.load(userId, currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          kuchikomiHandler.load(userId, (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <MyPageTemplate
      head="マイページ"
      user={user}
      userLoading={userLoading}
      isSp={isSp}
    >
      {!topLoading ? (
        <div className="flex justify-between flex-wrap pb-4">
          <div
            className={`pb-2 w-[47.5%] ${nextActionCount ? 'lg:w-[30%]' : ''}`}
          >
            {top && <MemberRankCard memberRank={top.memberRank} />}
          </div>
          <div
            className={`pb-2 w-[47.5%] ${nextActionCount ? 'lg:w-[30%]' : ''}`}
          >
            <PointCard
              holdPoint={top ? top.holdPointCount : 0}
              givedPoint={top ? top.givedPointCount : 0}
            />
          </div>
          {nextActionCount ? (
            <div className="lg:w-[30%] w-[100%] pb-2">
              <NextActionCard nextActions={top?.nextActions || []} />
            </div>
          ) : null}
        </div>
      ) : (
        <LoadingSpinner className="mx-auto mb-16" />
      )}
      {!topLoading ? (
        <div className="pb-4">
          <KuchikomiSasukatsuCard
            kuchikomiCount={top?.kuchikomiCount || 0}
            kuchikomiEnterpriseCount={top?.kuchikomiEnterpriseCount || 0}
            pace={top?.kuchikomiPace || 0}
            evaluationAverage={top?.evaluationAverage ?? null}
            isSp={isSp}
          />
        </div>
      ) : (
        <LoadingSpinner className="mx-auto mb-16" />
      )}
      <div className="pb-4">
        {top?.hints
          ? top.hints.map((item, index) => {
              return (
                <div key={`hint-${String(index)}`}>
                  <HintCard hint={item} />
                </div>
              )
            })
          : null}
      </div>
      <div className="mb-8 sm:mb-4">
        {!topLoading ? (
          <InformationCard informations={top?.informations || []} />
        ) : (
          <LoadingSpinner className="mx-auto mb-16" />
        )}
      </div>
      <div className="mb-8 sm:mb-4">
        {!topLoading ? (
          <NotificationCard notifications={top?.notifications || []} />
        ) : (
          <LoadingSpinner className="mx-auto mb-16" />
        )}
      </div>
      <div className="pb-4">
        {!topLoading ? (
          kuchikomiElems
        ) : (
          <LoadingSpinner className="mx-auto mb-16" />
        )}
      </div>
      <div className="pb-4">{paginationCursor}</div>
    </MyPageTemplate>
  )

  return content
}

export default MyPageTop
