import React, { FC } from 'react'
import { EvalurationStarIcon } from '../../../../images/icons/star_icon'
import SpeechBubble from '../slider/speech_bubble'
import { Link } from 'react-router-dom'
import { Kuchikomi } from '../../../../model/Kuchikomi'
import { EnterpriseSimple } from '../../../../clients/api_client'
import { TopSliderHeartIcon } from '../../../../images/icons/top_slider_heart'
import { getKiloString } from '../../../../utils/numeric_util'

type EnterpriseKuchikomiCardProps = {
  enterprise: EnterpriseSimple
  relatedKuchikomis: Array<Kuchikomi>
}

const EnterpriseKuchikomiCard: FC<EnterpriseKuchikomiCardProps> = (props) => {
  const KUCHIKOMIDISPLAYCOUNT = 3

  const elem = (
    <div className="bg-white rounded-3xl block min-w-[296px] w-full mx-6 px-6 sm:px-8 pt-6 pb-2 sm:pt-9 sm:pb-6">
      <Link to={`enterprises/${props.enterprise.id}`}>
        <div className="flex justify-start">
          <div className="my-auto  mb-6">
            <p className="font-bold text-main-black text-lg leading-normal mb-2">
              {props.enterprise.name}
            </p>
            <div className="flex justify-start">
              <div className="flex justify-start">
                <div className="flex flex-col justify-center mr-2">
                  <EvalurationStarIcon
                    evaluation={props.enterprise.evaluationAverage ?? null}
                  />
                </div>
                <div className="flex flex-col justify-center pr-4">
                  <p className="text-main-darkGray text-sm">
                    {props.enterprise.evaluationAverage
                      ? props.enterprise.evaluationAverage.toFixed(2)
                      : '-'}
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-center">
                <p className="text-main-darkGray text-xs">
                  クチコミ件数&nbsp;
                  {props.enterprise.kuchikomiCount
                    ? props.enterprise.kuchikomiCount.toLocaleString()
                    : '0'}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          {props.relatedKuchikomis
            .slice(0, KUCHIKOMIDISPLAYCOUNT)
            .map((kuchikomi) => {
              return (
                <div key={`${kuchikomi.id}`} className="mb-1">
                  <div className="flex justify-between">
                    <div className="w-[calc(100%-3rem)] pr-4 flex flex-col justify-center">
                      <SpeechBubble
                        content={
                          <p className="line-clamp-1">{kuchikomi.kuchikomi}</p>
                        }
                      />
                    </div>
                    <Link
                      to={`/users/${kuchikomi.reviewer.id}`}
                      className={`rounded-full bg-center bg-cover block w-10 h-10 mr-1`}
                      style={{
                        backgroundImage: `url(${kuchikomi.reviewer.iconUrl})`,
                      }}
                    />
                  </div>
                  <div className="text-xs w-[calc(100%-4.5rem)] text-main-pink flex items-center justify-end relative -top-1">
                    <TopSliderHeartIcon />
                    <span className="pl-[1px] relative">
                      {getKiloString(kuchikomi.likeCount)}
                    </span>
                  </div>
                </div>
              )
            })}
        </div>
      </Link>
    </div>
  )

  return elem
}

export default EnterpriseKuchikomiCard
