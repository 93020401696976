import dayjs, { Dayjs } from 'dayjs'
import CouponService from './CouponService'

class Coupon {
  id: string
  title: string
  description: string
  imageUrl: string
  expireDate: Dayjs
  point: number
  couponService: CouponService

  constructor(
    id: string,
    title: string,
    description: string,
    imageUrl: string,
    expireDate: string,
    point: number,
    couponService: CouponService
  ) {
    this.id = id
    this.title = title
    this.description = description
    this.imageUrl = imageUrl
    this.expireDate = dayjs(expireDate)
    this.point = point
    this.couponService = couponService
  }
}

export default Coupon
