import React, { useEffect, useState } from 'react'
import MyPageTemplate from './components/templates/mypage_template'
import useUser from './hooks/use_user'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import useEnterpriseFollows from './hooks/use_enterprise_follow'
import EnterpriseCard from './components/cards/enterprise_card'
import PagiNationCursor from '../../components/pagination/pagination_cursor'
import LoadingSpinner from '../../components/loading/loading_spinner'

const MyPageEnterpriseFollow = () => {
  const PAGE_SIZE = 20

  const isSp = useSpBreakPoint()
  const [user, userLoading] = useUser()
  const [userId, setUserId] = useState('')
  const [
    enterpriseFollows,
    size,
    currentCursor,
    isLoading,
    enterpriseFollowsHandler,
  ] = useEnterpriseFollows()
  useEffect(() => {
    if (user) {
      setUserId(user.id)
      enterpriseFollowsHandler.load(user?.id)
    }
  }, [user])

  const followElems = (
    <>
      <div className="rounded-xl overflow-hidden bg-white">
        {enterpriseFollows?.map((item, index) => {
          return (
            <div key={`follow-${String(index)}`}>
              <EnterpriseCard enterprise={item} />
            </div>
          )
        })}
      </div>
      {enterpriseFollows.length === 0 && !isLoading && (
        <div className="text-center py-4 text-gray-400">
          フォローしている企業・団体はいません
        </div>
      )}
    </>
  )

  const IndexSectionRef = React.createRef<HTMLDivElement>()
  const scrollToIndexSection = () => {
    if (IndexSectionRef.current) {
      const elementPosition =
        IndexSectionRef.current.getBoundingClientRect().top + window.scrollY
      const offsetPosition = elementPosition - 100
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }

  const paginationCursor =
    enterpriseFollows.length > 0 ? (
      <PagiNationCursor
        pageCount={Math.ceil(size / PAGE_SIZE)}
        numRange={3}
        activePageNum={Math.floor(currentCursor / PAGE_SIZE) + 1}
        next={() => {
          scrollToIndexSection()
          enterpriseFollowsHandler.load(userId, currentCursor + PAGE_SIZE)
        }}
        prev={() => {
          scrollToIndexSection()
          enterpriseFollowsHandler.load(userId, currentCursor - PAGE_SIZE)
        }}
        jump={(pageNum: number) => {
          scrollToIndexSection()
          enterpriseFollowsHandler.load(userId, (pageNum - 1) * PAGE_SIZE)
        }}
      />
    ) : null

  const content = (
    <MyPageTemplate
      userLoading={userLoading}
      head="フォローした企業・団体"
      user={user}
      isSp={isSp}
      subHead={userLoading ? '' : `${size.toLocaleString()}社`}
    >
      <div ref={IndexSectionRef} />
      {isLoading || userLoading ? (
        <LoadingSpinner className="mx-auto" />
      ) : (
        <>
          <div className="pb-8">{followElems}</div>
          <div className="pb-8">{paginationCursor}</div>
        </>
      )}
    </MyPageTemplate>
  )

  return content
}

export default MyPageEnterpriseFollow
