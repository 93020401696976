import React, { FC } from 'react'

type BellIconProps = {
  color: 'WHITE' | 'LIGHTGRAY'
}

export const BellIcon: FC<BellIconProps> = (props) => {
  let fillColor = '#FFFFFF'
  switch (props.color) {
    case 'WHITE':
      break
    case 'LIGHTGRAY':
      fillColor = '#b9b9b9'
      break
    default:
      fillColor = '#FFFFFF'
      break
  }

  return (
    <div className="min-w-[16px]">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="24"
        viewBox="0 0 21 24"
      >
        <path
          id="FontAwsome_bell_"
          data-name="FontAwsome (bell)"
          d="M20.6,16.982C19.69,16.009,18,14.545,18,9.75a7.4,7.4,0,0,0-6-7.273V1.5a1.5,1.5,0,1,0-3,0v.977A7.4,7.4,0,0,0,3,9.75c0,4.8-1.695,6.259-2.6,7.232A1.465,1.465,0,0,0,0,18a1.5,1.5,0,0,0,1.5,1.5H19.495A1.5,1.5,0,0,0,21,18a1.464,1.464,0,0,0-.4-1.018ZM3.165,17.25c.995-1.311,2.082-3.484,2.087-7.473,0-.009,0-.018,0-.027a5.25,5.25,0,0,1,10.5,0c0,.009,0,.018,0,.027.005,3.989,1.093,6.162,2.087,7.473ZM10.5,24a3,3,0,0,0,3-3h-6A3,3,0,0,0,10.5,24Z"
          transform="translate(0.001)"
          fill={fillColor}
        />
      </svg>
    </div>
  )
}
