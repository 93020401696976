import React, { ReactNode, useEffect, useState } from 'react'
import { createContext, FC } from 'react'
import { useRecoilValue } from 'recoil'
import { useSnackBar } from './snack_bar_provider'
import { AuthModule } from '../recoil/auth/modules'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../utils/network_util'
import { LicenseType } from '../enums/LicenseType'
import { currentUser } from '../recoil/auth/atoms'
import { CurrentUser } from '../recoil/auth/type'
import { AuthenticatedUser } from '../clients/api_client'

type AuthProviderProps = {
  children: ReactNode
}

type AuthContextProps = {
  user: CurrentUser
  isLoading: boolean
  emailSignIn: (loginId: string, password: string) => Promise<boolean>
  signIn: (user: AuthenticatedUser, token: string) => void
  signOut: () => void
}

export const AuthContext = createContext<AuthContextProps>(
  {} as AuthContextProps
)

const getLicenseType = (apiLicenseType: 'user' | 'enterprise') => {
  return apiLicenseType === 'user' ? LicenseType.NORMAL : LicenseType.CORP
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const user = useRecoilValue(currentUser)
  const [isLoading, setIsLoading] = useState(true)
  const { showSnackBar } = useSnackBar()

  useEffect(() => {
    loadUserFromLocalAccessToken()
  }, [])

  /**
   * ローカルストレージに保存されているアクセストークンからユーザー情報を取得する
   * @return
   */
  const loadUserFromLocalAccessToken = async () => {
    const token = window.localStorage.getItem('ACCESS-TOKEN')

    if (token === null) {
      AuthModule.deactivate()
      setIsLoading(false)
      return
    }

    // API通信前に一旦適当な値でユーザー情報をセットしておく
    AuthModule.init()

    const api = getAppClient()
    const response = await api.auth.checkCreate().catch(() => {
      return null
    })

    if (response === null) {
      signOut()
      setIsLoading(false)
      return null
    }

    AuthModule.activate(
      response.data.user.displayName,
      response.data.user.iconUrl,
      getLicenseType(response.data.user.licenseType),
      response.data.user.kuchikomiEnterpriseId ?? null,
      response.data.user.isPaidPlan
    )

    setIsLoading(false)
    return true
  }

  /**
   * メールアドレスとパスワードでログインする
   */
  const emailSignIn = async (
    email: string,
    password: string
  ): Promise<boolean> => {
    const api = getAppClient()

    const response = await api.auth
      .loginEmailCreate({
        email: email,
        password: password,
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return null
      })

    if (response === null) {
      return false
    }

    signIn(response.data.user, response.data.token)

    return true
  }

  const signIn = (user: AuthenticatedUser, token: string) => {
    window.localStorage.setItem('ACCESS-TOKEN', token)
    AuthModule.activate(
      user.displayName,
      user.iconUrl,
      getLicenseType(user.licenseType),
      user.kuchikomiEnterpriseId ?? null,
      user.isPaidPlan
    )
  }

  const signOut = async () => {
    AuthModule.deactivate()
    window.localStorage.removeItem('ACCESS-TOKEN')
  }

  const contextValues = {
    user: user,
    isLoading: isLoading,
    emailSignIn: emailSignIn,
    signOut: signOut,
    signIn: signIn,
  }

  return (
    <AuthContext.Provider value={contextValues}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
