import React, { FC } from 'react'
import { RankIcon } from '../../../../images/icons/rank_icon'
import { Link } from 'react-router-dom'
import { EnterpriseRanking } from '../../../../model/Ranking'
import { EvalurationStarIcon } from '../../../../images/icons/star_icon'

type EnterpriseRankingCardProps = {
  ranking: EnterpriseRanking
}

const EnterpriseRankingCard: FC<EnterpriseRankingCardProps> = (props) => {
  return (
    <Link to={`/enterprises/${props.ranking.enterprise.id}`}>
      <div className="bg-white py-6 px-2 relative rounded-xl">
        <div className="flex flex-col justify-center absolute -top-2 -left-2">
          <RankIcon rank={props.ranking.rank} />
        </div>
        <div>
          <div>
            <p className="font-bold text-main-black break-words whitespace-pre-wrap">
              {props.ranking.enterprise ? props.ranking.enterprise.name : ''}
            </p>
          </div>
          <div className="pb-4">
            <p className="font-bold text-main-lightGray text-sm">
              {props.ranking.enterprise
                ? props.ranking.enterprise.industryParentCode.value
                : ''}
            </p>
          </div>
          <div className="flex justify-start">
            <div className="flex flex-col justify-center mr-2">
              <EvalurationStarIcon
                evaluation={props.ranking.enterprise.evaluationAverage ?? null}
              />
            </div>
            <div className="flex flex-col justify-center">
              <p className="text-main-darkGray">
                {props.ranking.enterprise.evaluationAverage
                  ? props.ranking.enterprise.evaluationAverage.toFixed(2)
                  : '-'}
              </p>
            </div>
          </div>
          <div>
            <p className="text-main-darkGray text-xs">
              クチコミ件数&nbsp;
              {props.ranking.enterprise &&
              props.ranking.enterprise.kuchikomiCount
                ? props.ranking.enterprise.kuchikomiCount.toLocaleString()
                : '0'}
            </p>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default EnterpriseRankingCard
