import React, { FC } from 'react'
import { BookmarkIcon } from '../../images/icons/bookmark_icon'
import AuthUser from '../../model/AuthUser'
import { LicenseType } from '../../enums/LicenseType'
import { useAuth } from '../../hooks/use_auth'

type BookmarkButtonProps = {
  isActive: boolean
  bookmarkCount?: number
  isSp?: boolean
  onClick?: (e: React.MouseEvent) => void
}

const BookmarkButton: FC<BookmarkButtonProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSp = false,
  ...props
}) => {
  const { user } = useAuth()
  const isKuchikomiUser = user?.licenseType === LicenseType.NORMAL

  // Always use inactive color for corporate users
  let textColor = 'text-main-lightGray'
  if (props.isActive && isKuchikomiUser) {
    textColor = 'text-main-blue font-bold'
  }

  const handleClick = (e: React.MouseEvent) => {
    // Disable functionality for corporate users
    if (!isKuchikomiUser) {
      return
    }

    // Call the original onClick handler if provided
    props.onClick?.(e)
  }
  if (!isKuchikomiUser) {
    return <></>
  }

  return (
    <div
      className={`flex justify-start ${isKuchikomiUser ? 'cursor-pointer' : 'cursor-default'}`}
      onClick={handleClick}
    >
      <div className="flex flex-col justify-center mr-1">
        <BookmarkIcon isActive={isKuchikomiUser ? props.isActive : false} />
      </div>
      <div className="flex flex-col justify-center">
        <p className={`${textColor} text-xs pl-1 whitespace-nowrap`}>
          ブックマーク
        </p>
      </div>
    </div>
  )
}

export default BookmarkButton
