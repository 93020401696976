import React, { FC, useEffect, useState } from 'react'
import CommonTemplate from '../../components/templates/common_template'
import { useSpBreakPoint } from '../../hooks/use_sp_breakpoint'
import ExpoFVImage from './images/fv.png'
import { Helmet } from 'react-helmet'
import { ExpoCategoryLabel, ExpoCategoryValues } from './category'
import { EnterpriseRanking } from '../../model/Ranking'
import Head2 from '../../components/heads/head2'
import LoadingSpinner from '../../components/loading/loading_spinner'
import { EnterpriseRankingCard } from '../../components/card/enterprise_card'
import { useSnackBar } from '../../providers/snack_bar_provider'
import {
  ApiErrorResponse,
  getAppClient,
  handleError,
} from '../../utils/network_util'
import { Link } from 'react-router-dom'

const ogpDescription =
  'クチコミ万博では、Osaka EXPO 2025の最新情報や参加者の評判・レビュー・評価を集め、海外＆国内パビリオン、グルメ、グッズ＆ショップ、イベントなど、万博をより深く楽しむための情報を提供します。実際の体験をもとに、万博の魅力を発見しましょう！'
const ogpKeywords =
  '万博,Expo,大阪,Osaka,クチコミ,クチコミ,レビュー,採点,評価,評判,カスタマーレビュー,コメント,ランキング,持続可能,サステナブル,Sustainable,ESG ,エシカル , ethical,Review'
const ogpTitle =
  'クチコミ万博 | Osaka EXPO 2025の評判・レビューで楽しむ海外＆国内パビリオン、グルメ＆イベント'

const ExpoPage: FC = () => {
  const isSp = useSpBreakPoint()
  const [rankingLoading, setRankingLoading] = useState(false)
  const [enterpriseRankings, setEnterpriseRankings] = useState<
    EnterpriseRanking[]
  >([])
  const apiClient = getAppClient()
  const { showSnackBar } = useSnackBar()

  useEffect(() => {
    loadEnterpriseRankings()
  }, [])

  const loadEnterpriseRankings = async () => {
    setRankingLoading(true)

    await apiClient.ranking
      .enterpriseExpoList()
      .then((res) => {
        const result = res.data
        setEnterpriseRankings(result.data)
      })
      .catch((e: ApiErrorResponse) => {
        handleError(e, showSnackBar)
        return null
      })
      .finally(() => {
        setRankingLoading(false)
      })
  }

  return (
    <CommonTemplate isPadding={!isSp} isSp={isSp} isShowPostButton={false}>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Round|Material+Icons+Sharp|Material+Icons+Two+Tone"
          rel="stylesheet"
        />
        <meta name="description" content={ogpDescription} />
        <meta property="og:description" content={ogpDescription} />
        <meta name="twitter:description" content={ogpDescription} />
        <meta name="keywords" content={ogpKeywords} />
        <meta property="og:image" content={ExpoFVImage} />
        <meta name="twitter:image:src" content={ExpoFVImage} />
        <meta property="og:title" content={ogpTitle} />
        <meta name="twitter:title" content={ogpTitle} />
        <title>{ogpTitle}</title>
      </Helmet>

      <div className="bg-white rounded-xl w-full py-12 px-16 mb-32">
        <img src={ExpoFVImage} alt="expo" className="w-full mb-12" />
        <div className="mb-16">
          <h2 className="font-bold text-2xl mb-4">クチコミ万博とは！？</h2>
          <p>2025年4月13日から10月13日まで開催される 大阪・関西万博。</p>
          <p>
            「いのち輝く未来社会のデザイン」をテーマに、世界の最先端技術や文化、持続可能な社会への取り組みを体験できる一大イベントです。
          </p>
          <p>
            広大な会場には、未来の暮らしを体験できるパビリオン、世界のグルメを楽しめるエリア、地球環境を考えたSDGs関連展示、ライブやパフォーマンスが楽しめるエンターテインメントプログラム
            など、魅力的なスポットが数多くあります。
          </p>
          <p>
            「どこを回るべき？」「おすすめのスポットは？」そんな疑問に答えるのが、みんなのクチコミでつくる大阪・関西万博のおすすめスポットガイド
            です。
          </p>
          <p>
            リアルな体験談をチェックしながら、自分にぴったりのスポットを見つけてみませんか？
          </p>
          <p>
            そして、実際に訪れたら、あなたの感想やおすすめポイントもシェアして、大阪・関西万博を一緒に盛り上げましょう！
          </p>
        </div>

        <h3 className="flex justify-between px-4 items-center bg-main-darkblue rounded-md py-4 text-white mb-8">
          <span className="text-2xl font-bold ">投稿のポイントを紹介</span>
          <span className="material-icons">expand_more</span>
        </h3>
        <div className="mb-16">
          <p className="mb-8">
            「どんなクチコミを書けばいいの？」と迷っている方のために、投稿のポイント
            をご紹介します！
          </p>
          <div className="mb-8">
            <p className="font-bold">① どのスポットがおすすめ？</p>
            <p>
              「このパビリオンがすごかった！」「意外な穴場スポットを発見！」など、あなたが訪れた場所の見どころを教えてください。
            </p>
          </div>
          <div className="mb-8">
            <p className="font-bold">② どんな体験ができた？</p>
            <p>
              「未来の技術を体験できた」「SDGsについて学べる展示が面白かった」「ここでしか食べられない絶品グルメがあった」など、実際に感じたことを具体的に書くと、読んだ人がイメージしやすくなります。
            </p>
          </div>
          <div className="mb-8">
            <p className="font-bold">③ どんな人におすすめ？</p>
            <p>
              「子ども連れにぴったり」「写真映えするスポット」「ゆっくり楽しみたい人向け」など、訪れる人に合った楽しみ方を提案すると、さらに参考になります！
            </p>
          </div>
          <p>
            あなたの投稿が、大阪・関西万博を訪れる人にとって貴重な情報になります。
          </p>
          <p>
            さあ、「クチコミ万博」であなたの体験をシェアして、一緒に万博を盛り上げましょう！
          </p>
        </div>
        <div>
          <h4 className="mb-4 font-bold text-2xl">グループ検索</h4>
          <div className="flex items-center flex-wrap gap-4">
            {ExpoCategoryValues.map((cate) => (
              <Link to={'/kuchikomi-banpaku/' + cate} key={cate}>
                <div className="bg-main-darkblue py-4 w-52 text-center text-white font-bold text-base rounded-md">
                  {ExpoCategoryLabel[cate]}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>

      <div className="mb-4">
        <div className="mb-8">
          <Head2 title={`企業・団体ランキング`} />
        </div>
        {rankingLoading ? (
          <LoadingSpinner className="mx-auto" />
        ) : (
          <>
            <div className="flex md:justify-between justify-center flex-wrap">
              {enterpriseRankings.map((item, index) => {
                return (
                  <div
                    key={`ranking-${String(index)}`}
                    className="md:w-[47.5%] w-full min-w-[280px] pb-4"
                  >
                    <EnterpriseRankingCard ranking={item} />
                  </div>
                )
              })}
            </div>
          </>
        )}
      </div>
    </CommonTemplate>
  )
}

export default ExpoPage
