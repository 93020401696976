import React, { FC } from 'react'
import User from '../../../model/User'
import Enterprise from '../../../model/Enterprise'
import FlatButton from '../../buttons/flat_button'
import { FilledFavoriteIcon } from '../../../images/icons/favorite_icon'
import { FollowerIcon } from '../../../images/icons/follower_icon'
import { EvalurationStarIcon } from '../../../images/icons/star_icon'
import { Link } from 'react-router-dom'

type FollowModalProps = {
  tab: 'follow' | 'follower' | 'enterprise'
  follows: Array<User> | null
  followers: Array<User> | null
  enterprises: Array<Enterprise> | null
  onChangeTab: (next: 'follow' | 'follower' | 'enterprise') => void
  onFollow: (
    id: string,
    set: boolean,
    type: 'enterprise' | 'user'
  ) => Promise<void>
}

const FollowModal: FC<FollowModalProps> = (props) => {
  let content = null
  switch (props.tab) {
    case 'follow':
      content = props.follows ? (
        <>
          {props.follows.map((item, index, ary) => {
            return (
              <UserLine
                key={`follow-user-${String(index)}`}
                isLastItem={ary.length - 1 === index}
                onFollow={props.onFollow}
                {...item}
              />
            )
          })}
          {props.follows.length === 0 && (
            <div className="w-full text-center pt-8">
              フォローしているユーザーがいません
            </div>
          )}
        </>
      ) : null
      break
    case 'follower':
      content = props.followers ? (
        <>
          {props.followers.map((item, index, ary) => {
            return (
              <UserLine
                key={`follower-user-${String(index)}`}
                isLastItem={ary.length - 1 === index}
                onFollow={props.onFollow}
                {...item}
              />
            )
          })}
          {props.followers.length === 0 && (
            <div className="w-full text-center pt-8">フォロワーがいません</div>
          )}
        </>
      ) : null
      break
    case 'enterprise':
      content = props.enterprises ? (
        <>
          {props.enterprises.map((item, index, ary) => {
            return (
              <EnterpriseLine
                key={`enterprise-${String(index)}`}
                isLastItem={ary.length - 1 === index}
                {...item}
              />
            )
          })}
          {props.enterprises.length === 0 && (
            <div className="w-full text-center pt-8">
              口コミした 企業・団体がありません
            </div>
          )}
        </>
      ) : null
      break
  }

  return (
    <div className="bg-white">
      <div className="flex items-center">
        <div
          className={`w-[8rem] h-[4rem] flex justify-center items-center cursor-pointer border-b-4 ${
            props.tab === 'follow' ? 'border-b-main-blue' : 'border-b-main-gray'
          }`}
          onClick={() => props.onChangeTab('follow')}
        >
          <p className="text-sm">フォロー</p>
        </div>
        <div
          className={`w-[8rem] h-[4rem] flex justify-center items-center cursor-pointer border-b-4 ${
            props.tab === 'follower'
              ? 'border-b-main-blue'
              : 'border-b-main-gray'
          }`}
          onClick={() => props.onChangeTab('follower')}
        >
          <p className="text-sm">フォロワー</p>
        </div>
        <div
          className={`w-[8rem] h-[4rem] flex justify-center items-center cursor-pointer border-b-4 ${
            props.tab === 'enterprise'
              ? 'border-b-main-blue'
              : 'border-b-main-gray'
          }`}
          onClick={() => props.onChangeTab('enterprise')}
        >
          <p className="text-sm leading-snug">
            口コミした
            <br />
            企業・団体
          </p>
        </div>
      </div>
      <div className="border-b-4 border-b-main-gray max-h-[70vh] h-[500px] overflow-y-auto">
        {content}
      </div>
    </div>
  )
}

export default FollowModal

const UserLine: FC<
  User & {
    key: string
    isLastItem: boolean
    onFollow: (
      id: string,
      set: boolean,
      type: 'enterprise' | 'user'
    ) => Promise<void>
  }
> = (props) => {
  return (
    <Link to="#">
      <div
        key={props.key}
        className={`p-4 flex justify-between ${
          props.isLastItem ? '' : 'border-b border-b-main-lightGray'
        }`}
      >
        <div className="flex justify-start">
          <div
            className="w-12 h-12 rounded-full bg-cover bg-center mr-4"
            style={{
              backgroundImage: `url(${props.iconUrl})`,
            }}
          />
          <div>
            <p>{props.displayName}</p>
            <div className="flex justify-start">
              <div className="flex justify-start pr-4">
                <div className="flex flex-col justify-center">
                  <FilledFavoriteIcon />
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-sm text-main-lightGray">
                    {props.givedPointCount}
                  </p>
                </div>
              </div>
              <div className="flex justify-start">
                <div className="flex flex-col justify-center">
                  <FollowerIcon />
                </div>
                <div className="flex flex-col justify-center">
                  <p className="text-sm text-main-lightGray">
                    {props.followerCount}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <FlatButton
            bgColor={props.yourFollower ? 'lightGray' : 'blue'}
            onClick={() =>
              props.onFollow(props.id, props.yourFollower || false, 'user')
            }
          >
            <p className="text-white px-2 leading-4">
              {props.yourFollower ? 'フォロー中' : 'フォロー'}
            </p>
          </FlatButton>
        </div>
      </div>
    </Link>
  )
}

const EnterpriseLine: FC<Enterprise & { key: string; isLastItem: boolean }> = (
  props
) => {
  return (
    <Link to="#">
      <div key={props.key} className={`py-2 px-4`}>
        <div>
          <p className="leading-6">{props.name}</p>
        </div>
        <div>
          <p className="text-sm text-main-lightGray">
            {props.industryParentCode.value}
          </p>
        </div>
        <div className="flex justify-start">
          <div className="flex justify-start">
            <div className="flex flex-col justify-center pr-2">
              <EvalurationStarIcon evaluation={props.evaluationAverage ?? null} />
            </div>
            <p className="text-sm flex flex-col justify-center">
              {props.evaluationAverage
                ? props.evaluationAverage.toFixed(2)
                : '-'}
            </p>
          </div>
          <div className="flex flex-col justify-center">
            <p className="text-xs text-main-lightGray leading-8 pl-2">
              （
              {props.kuchikomiCount ? props.kuchikomiCount.toLocaleString() : 0}
              件）
            </p>
          </div>
        </div>
      </div>
    </Link>
  )
}
