/**
 * 万博カテゴリー
 */
export const ExpoCategory = {
  /** 国内系パビリオン */
  DOMESTIC: 'domestic',

  /** 海外系パビリオン */
  FOREIGN: 'foreign',

  /** グルメ */
  FOOD: 'food',

  /** グッズ＆ショップ */
  GOODS: 'goods',

  /** イベント */
  EVENT: 'event',

  /** 会場施設 */
  FACILITY: 'facility',

  /** その他 */
  OTHER: 'other',
} as const

export type ExpoCategoryValue = (typeof ExpoCategory)[keyof typeof ExpoCategory]

export const ExpoCategoryLabel = {
  [ExpoCategory.DOMESTIC]: '国内系パビリオン',
  [ExpoCategory.FOREIGN]: '海外パビリオン',
  [ExpoCategory.FOOD]: 'グルメ',
  [ExpoCategory.GOODS]: 'グッズ＆ショップ',
  [ExpoCategory.EVENT]: 'イベント',
  [ExpoCategory.FACILITY]: '会場施設',
  [ExpoCategory.OTHER]: 'その他',
}

export const ExpoCategoryValues = [
  ExpoCategory.DOMESTIC,
  ExpoCategory.FOREIGN,
  ExpoCategory.FOOD,
  ExpoCategory.GOODS,
  ExpoCategory.EVENT,
  ExpoCategory.FACILITY,
  ExpoCategory.OTHER,
]
